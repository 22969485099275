import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { trackEvent } from '../util/gtm';
import { Checkbox } from './components/Checkbox';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';
import { saveStateRemote } from './util';

const CardsStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();
  const { creditCardsDinersClub, creditCardsMasterCard, creditCardsPetrol, creditCardsVisa, debitCardsMasterCard, dankort } = state;

  useEffect(() => {
    trackEvent('Flow', 'Load', 'CardsStep');
    dispatch({ type: 'set_step', step: Step.Cards });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => { await saveStateRemote(state); }
    saveState();
  }, []);

  return (
    <>
      <h2>Betalingskort?</h2>

      <h3>Hvilke købe- og kreditkort har du?</h3>

      <Formik
        initialValues={{ creditCardsDinersClub, creditCardsMasterCard, creditCardsPetrol, creditCardsVisa, debitCardsMasterCard, dankort }}
        onSubmit={async (values) => {
          dispatch({ 
            type: 'set_cards', 
            creditCardsDinersClub: values.creditCardsDinersClub,
            creditCardsMasterCard: values.creditCardsMasterCard,
            creditCardsPetrol: values.creditCardsPetrol,
            creditCardsVisa: values.creditCardsVisa,
            debitCardsMasterCard: values.debitCardsMasterCard,
            dankort: values.dankort
          });
          history.push('/betingelser');
        }}
      >
        {(formikProps) => (
          <div className="mt-5">
            <div>
              <Checkbox
                isStatic={true}
                heading="Dankort"
                checked={formikProps.values?.dankort}
                isInvalid={formikProps.values?.dankort !== undefined}
                onChange={(checked) => {
                  formikProps.setValues({
                    ...formikProps.values,
                    dankort: checked,
                  });
                }}
              >
                {(formikProps.touched.dankort || formikProps.submitCount > 0) &&
                  formikProps.errors.dankort && (
                    <div className="invalid-feedback">
                      {formikProps.errors.dankort}
                    </div>
                  )}
              </Checkbox>
            </div>

            <div className="mt-1">
              <Checkbox
                isStatic={true}
                heading="MasterCard debit"
                checked={formikProps.values?.debitCardsMasterCard}
                isInvalid={
                  formikProps.values?.debitCardsMasterCard !== undefined
                }
                onChange={(checked) => {
                  formikProps.setValues({
                    ...formikProps.values,
                    debitCardsMasterCard: checked,
                  });
                }}
              >
                {(formikProps.touched.debitCardsMasterCard ||
                  formikProps.submitCount > 0) &&
                  formikProps.errors.debitCardsMasterCard && (
                    <div className="invalid-feedback">
                      {formikProps.errors.debitCardsMasterCard}
                    </div>
                  )}
              </Checkbox>
            </div>

            <div className="mt-1">
              <Checkbox
                isStatic={true}
                heading="MasterCard"
                checked={formikProps.values?.creditCardsMasterCard}
                isInvalid={
                  formikProps.values?.creditCardsMasterCard !== undefined
                }
                onChange={(checked) => {
                  formikProps.setValues({
                    ...formikProps.values,
                    creditCardsMasterCard: checked,
                  });
                }}
              >
                {(formikProps.touched.creditCardsMasterCard ||
                  formikProps.submitCount > 0) &&
                  formikProps.errors.creditCardsMasterCard && (
                    <div className="invalid-feedback">
                      {formikProps.errors.creditCardsMasterCard}
                    </div>
                  )}
              </Checkbox>
            </div>

            <div className="mt-1">
              <Checkbox
                isStatic={true}
                heading="VISA"
                checked={formikProps.values?.creditCardsVisa}
                isInvalid={formikProps.values?.creditCardsVisa !== undefined}
                onChange={(checked) => {
                  formikProps.setValues({
                    ...formikProps.values,
                    creditCardsVisa: checked,
                  });
                }}
              >
                {(formikProps.touched.creditCardsVisa ||
                  formikProps.submitCount > 0) &&
                  formikProps.errors.creditCardsVisa && (
                    <div className="invalid-feedback">
                      {formikProps.errors.creditCardsVisa}
                    </div>
                  )}
              </Checkbox>
            </div>

            <div className="mt-1">
              <Checkbox
                isStatic={true}
                heading="Diners Club"
                checked={formikProps.values?.creditCardsDinersClub}
                isInvalid={
                  formikProps.values?.creditCardsDinersClub !== undefined
                }
                onChange={(checked) => {
                  formikProps.setValues({
                    ...formikProps.values,
                    creditCardsDinersClub: checked,
                  });
                }}
              >
                {(formikProps.touched.creditCardsDinersClub ||
                  formikProps.submitCount > 0) &&
                  formikProps.errors.creditCardsDinersClub && (
                    <div className="invalid-feedback">
                      {formikProps.errors.creditCardsDinersClub}
                    </div>
                  )}
              </Checkbox>
            </div>

            <div className="mt-1">
              <Checkbox
                isStatic={true}
                heading="Benzinkort"
                checked={formikProps.values?.creditCardsPetrol}
                isInvalid={formikProps.values?.creditCardsPetrol !== undefined}
                onChange={(checked) => {
                  formikProps.setValues({
                    ...formikProps.values,
                    creditCardsPetrol: checked,
                  });
                }}
              >
                {(formikProps.touched.creditCardsPetrol ||
                  formikProps.submitCount > 0) &&
                  formikProps.errors.creditCardsPetrol && (
                    <div className="invalid-feedback">
                      {formikProps.errors.creditCardsPetrol}
                    </div>
                  )}
              </Checkbox>
            </div>

            <div className="container-btn">
              <BackButton />
              <button
                className="btn-next"
                type="submit"
                onClick={() => formikProps.submitForm()}
              >
                Næste
              </button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default CardsStep;
