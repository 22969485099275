import React, { useContext, useEffect, useState } from 'react';
import { useAmounts } from './hooks/amounts';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import { saveStateRemote, thousandNumberSeperator } from './util';
import { LoadingState } from './hooks/loading';
import { Loading } from './components/Loading';
import { trackEvent } from '../util/gtm';
import { Range } from 'react-range';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';

const OtherAmountStep: React.FunctionComponent = () => {
  let STEP = 5000;

  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const { amounts, amountsState } = useAmounts();
  const history = useHistory();
  const [values, setValues] = useState([]);

  useEffect(() => {
    trackEvent('Flow', 'Load', 'OtherAmountStep');
    dispatch({ type: 'set_step', step: Step.OtherAmount });
    scrollToTopOfContent();

    /* store state */
    // const saveState = async () => { await saveStateRemote(state); }
    // saveState();
  }, []);

  useEffect(() => {
    if (state.amount) {
      setValues([state.amount]);
    }
  }, [state]);

  useEffect(() => {
    if (amounts.length > 0 && !values[0]) {
      const min = Math.min(...amounts);
      const max = Math.max(...amounts);
      const mid = (max - min) / 2 + min;

      setValues([mid]);
    }
  }, [amounts]);

  const handleClick = () => {
    const amount = values[0];
    dispatch({ type: 'set_amount', values: amount });
    history.push(`/produkt/${amount}`);
  };

  return (
    <>
      <h2>Hvor meget vil du låne?</h2>

      {amountsState === LoadingState.Loading && (
        <Loading text="Henter lånebeløb..." />
      )}

      {amountsState === LoadingState.Success && amounts.length > 1 && (
        <div className="mt-20">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Range
              values={values}
              step={STEP}
              min={Math.min(...amounts)}
              max={Math.max(...amounts)}
              onChange={(values) => setValues(values)}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: '36px',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: '5px',
                      width: '100%',
                      borderRadius: '4px',
                      background: '#43bbaf',
                      alignSelf: 'center',
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '42px',
                    width: '42px',
                    borderRadius: '4px',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px 2px 6px #AAA',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '-40px',
                      color: '#fff',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                      padding: '4px',
                      borderRadius: '4px',
                      backgroundColor: '#43bbaf',
                    }}
                  >
                    {/* {values[0].toFixed(1)} */}
                    <div className="whitespace-nowrap">
                      {thousandNumberSeperator(values[0])} kr.
                    </div>
                  </div>
                  <div
                    style={{
                      height: '16px',
                      width: '5px',
                      backgroundColor: isDragged ? '#548BF4' : '#CCC',
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className="flex text-xs font-semibold mt-3">
            <div className="flex-1 text-lg text-regal-darkestblue">
              {thousandNumberSeperator(Math.min(...amounts))} kr.
            </div>
            <div className="flex-1 text-right text-lg text-regal-darkestblue">
              {thousandNumberSeperator(Math.max(...amounts))} kr.
            </div>
          </div>

          <div className="container-btn">
            <BackButton />
            <button className="btn-next" onClick={handleClick}>
              Næste
            </button>
          </div>
        </div>
      )}

      {amountsState === LoadingState.Success && amounts.length === 1 && (
        // this is only useful in test, should not be used in production
        <>
          <div className="text-center mt-5">Der kan kun vælges ét beløb!</div>
          <div className="text-center mt-3">
            <button
              className="btn-choice"
              onClick={() => {
                const amount = amounts[0];
                dispatch({ type: 'set_amount', values: amount });
                history.push(`/produkt/${amount}`);
              }}
            >
              {thousandNumberSeperator(amounts[0])} kr
            </button>
          </div>
          <div className="container-btn">
            <BackButton />
          </div>
        </>
      )}
    </>
  );
};

export default OtherAmountStep;
