const Line: React.FC<{ active: boolean }> = (props) => {
  
  return (
    <div
      className={`flex items-center justify-center  w-full ${props.active ? 'border-regal-darkblue' : 'border-gray-300'}`}
      style={{ height: '19px' }}
    >
      <div className={`h-0.5 w-full ${props.active ? 'bg-regal-darkblue' : 'bg-gray-300'}`}></div>
    </div>
  );
};

export { Line };
