import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory, Link } from 'react-router-dom';
import { trackEvent } from '../util/gtm';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';
import { saveStateRemote } from './util';

const AlternativesStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();

  useEffect(() => {
    trackEvent('Flow', 'Load', 'AlternativesStep');
    dispatch({ type: 'set_step', step: Step.Alternatives });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => {
      await saveStateRemote(state);
    };
    saveState();
  }, []);

  return (
    <>
      <h2>Desværre</h2>

      <div className="mt-5 text-center">
        Det er ikke muligt at tilbyde dig en kredit hos Resurs Bank A/S, når du
        er listet i RKI. Du kan i stedet finde attraktive lånemuligheder på{' '}
        <a href="https://www.lendo.dk/bilhandel?partner_length=6&partner_amount=539900&partner_loan_purpose=car&tracking_id=11691&utm_source=bilhandel&utm_medium=Integration">
          lendo.dk
        </a>
        .
      </div>

      <div className="container-btn">
        <BackButton />
        <Link
          to={{
            pathname: `https://www.lendo.dk/bilhandel?partner_length=6&partner_amount=${state.amount}&partner_loan_purpose=car&tracking_id=11691&utm_source=bilhandel&utm_medium=Integration`,
          }}
          target="_top"
        >
          <button type="button" className="btn-next" style={{ width: '300px' }}>
            Ja tak, send mig til lendo.dk
          </button>
        </Link>
      </div>
    </>
  );
};

export default AlternativesStep;
