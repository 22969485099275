import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../util/gtm';
import YesNo from './components/YesNo';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';
import { saveStateRemote } from './util';

const CitizenshipStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();

  useEffect(() => {
    trackEvent('Flow', 'Load', 'CitizenshipStep');
    dispatch({ type: 'set_step', step: Step.CitizenshipStep });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => { await saveStateRemote(state); }
    saveState();
  }, []);

  const handleClick = (citizenship: string) => {
    dispatch({ type: 'set_citizenship', values: citizenship });

    if(citizenship === 'DANISH')
    {
      // reset
      dispatch({ type: 'set_inDenmarkSinceYear', values: undefined });
      dispatch({ type: 'set_residentPermit', values: undefined });
      dispatch({ type: 'set_residentPermitValidDate', values: undefined });
    }

    history.push('/personinformation');
  };

  return (
    <>
      <h2>Statsborgerskab</h2>

      <div>
        <div className="mt-5">
          <YesNo
            yesText="Dansk"
            onYes={() => handleClick('DANISH')}
            noText="Andet"
            onNo={() => handleClick('OTHER')}
          ></YesNo>
        </div>

        <div className="container-btn">
          <BackButton />
        </div>
      </div>
    </>
  );
};

export default CitizenshipStep;
