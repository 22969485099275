import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import YesNo from './components/YesNo';
import { trackEvent } from '../util/gtm';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';
import { saveStateRemote } from './util';

const MemberOfAkasseStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();

  useEffect(() => {
    trackEvent("Flow", "Load", "MemberOfAkasseStep");
    dispatch({ type: 'set_step', step: Step.MemberOfAkasse });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => { await saveStateRemote(state); }
    saveState();
  }, []);
  
  const handle = (isMember: boolean) => {
    dispatch({ type: 'set_memberOfAkasse', values: isMember });
    history.push('/raadighedsbeloeb');
  };

  return (
    <>
      <h2>Er du medlem af en A-kasse?</h2>

      <div className="mt-5">
        <YesNo  
          onYes={() => handle(true)}
          onNo={() => handle(false)}
          >
        </YesNo>
      </div>

      <div className="container-btn">
        <BackButton />
      </div>

    </>
  );
};

export default MemberOfAkasseStep;
