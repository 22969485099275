import * as Sentry from '@sentry/browser';
import { Cookies } from 'react-cookie-consent';
import { ApiConfiguration } from '../api-configuration';
import { ApplicationApi } from '../lib-api';
import { ApplicationState } from './models/ApplicationState';

export function thousandNumberSeperator(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function logError(e: Error) {
  Sentry.captureException(e);
}

export const dealerTrackingKey = 'trsource';
export const adServiceTrackingKey = 'adclid';

export function setCookie(key: string, value: string, expiresDays?: number) {
  var expires = expiresDays ?? 7;
  Cookies.set(key, value, { expires: expires });
}

export function removeCookie(key: string) {
  Cookies.remove(key);
}

export function getCookie(key: string) {
  return Cookies.get(key);
}

export async function saveStateRemote(state: ApplicationState) {
  const api = new ApplicationApi(ApiConfiguration);

  console.log('state.shareOfExpenses' + state.shareOfExpenses ?? 'null');
  console.log('state.childrenInAgeGroup1' + state.childrenGroup1 ?? 'null');

  api.storeApplication({ storeApplicationRequest: state });
}
