import React, { useContext, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../util/gtm';
import YearSelect, { DateOptions } from './components/YearSelect';
import { Checkbox } from './components/Checkbox';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';
import { saveStateRemote } from './util';

const CoApplicantStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();
  const {
    coApplicantInfoGovermentId,
    coApplicantInfoMobile,
    coApplicantInfoEmail,
    coApplicantInfoEmploymentType,
    coApplicantInfoEmploymentYear,
  } = state;

  const employmentTypeOptions = [
    {
      value: 'WHITE_COLLAR_WORKER',
      label: 'Funktionær/Lønmodtager',
      isJob: true,
    },
    { value: 'PAID_BY_HOUR', label: 'Timelønnet', isJob: true },
    { value: 'PRE_PENSIONER', label: 'Førtidspensionist', isJob: false },
    { value: 'PENSIONAR', label: 'Pensionist', isJob: false },
    { value: 'STAYING_AT_HOME', label: 'Hjemmegående', isJob: false },
    { value: 'STUDENT', label: 'Studerende', isJob: false },
    { value: 'TRAINEE', label: 'Elev/Lærling', isJob: false },
    { value: 'MANAGER', label: 'Direktør', isJob: true },
    { value: 'EGEN_FORETAGARE', label: 'Selvstændig', isJob: true },
    { value: 'VIKARIAT', label: 'Midlertidigt job', isJob: true },
    { value: 'ARBETSLOS', label: 'Ledig', isJob: false },
    { value: 'OTHER', label: 'Anden ansættelse', isJob: true },
  ];

  const currentYear = new Date().getFullYear();
  const employmentYearOptions = [];
  const numberOfYear = 100;

  for (let i = currentYear; i >= currentYear - numberOfYear; i--) {
    employmentYearOptions.push({ value: i, label: i });
  }

  const isJob = (value: string) => {
    return employmentTypeOptions.find((opt) => opt.value === value)?.isJob;
  };

  const validationSchema = yup.object().shape({
    // creditStatusConsent: yup.boolean().oneOf([true], 'Husk at acceptere samtykke til KreditStatus'),

    coApplicantInfoGovermentId: yup
      .string()
      .length(10, 'Indtast CPR-nummer (f.eks. 2412505566)')
      .matches(
        /^((3[0-1])|([1-2][0-9])|(0[1-9]))((1[0-2])|(0[1-9]))(\d{2})?([\d]{4})$/,
        'Indtast CPR-nummer (f.eks. 2412505566)'
      )
      .required('Indtast CPR-nummer (f.eks. 2412505566)'),
      coApplicantInfoMobile: yup
      .number()
      .typeError('Indtast dit telefonnummer')
      .min(10000000, 'Indtast dit telefonnummer')
      .max(99999999, 'Indtast dit telefonnummer')
      .required('Indtast telefonnummer'),
    coApplicantInfoEmail: yup.string().email('Indtast e-mail').required('Indtast e-mail'),
    coApplicantInfoEmploymentType: yup.string().required('Vælg ansættelsesforhold'),
    coApplicantInfoEmploymentYear: yup.string().when('employmentType', {
      is: (value) => value && isJob(value),
      then: yup.string().required('Vælg ansat i nuværende job siden'),
      otherwise: yup.string(),
    }),
  });

  useEffect(() => {
    trackEvent('Flow', 'Load', 'CoApplicantStep');
    dispatch({ type: 'set_step', step: Step.CoApplicant });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => {
      await saveStateRemote(state);
    };
    saveState();
  }, []);

  return (
    <div>
      <h2>Indtast oplysninger om medansøger</h2>

      <h3>
        Medansøgeren skal også underskrive eSKAT med NemID til sidst i
        ansøgningen.
      </h3>

      <Formik
        initialValues={{
          coApplicantInfoGovermentId,
          coApplicantInfoMobile,
          coApplicantInfoEmail,
          coApplicantInfoEmploymentType,
          coApplicantInfoEmploymentYear,
        }}
        onSubmit={(values) => {
          dispatch({
            type: 'set_coApplicant',
            coApplicantInfoGovermentId: values.coApplicantInfoGovermentId,
            coApplicantInfoMobile: values.coApplicantInfoMobile,
            coApplicantInfoEmail: values.coApplicantInfoEmail,
            coApplicantInfoEmploymentType: values.coApplicantInfoEmploymentType,
            coApplicantInfoEmploymentYear: values.coApplicantInfoEmploymentYear,
          });
          history.push('/boligform');
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <div className="mt-5">
            <div>
              <label className="label-default">CPR-nummer</label>
              <input
                type="text"
                name="coApplicantInfoGovermentId"
                placeholder="CPR-nummer"
                defaultValue={formikProps.values.coApplicantInfoGovermentId}
                disabled={formikProps.isSubmitting}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                autoComplete="off"
                maxLength={10}
              />
              {(formikProps.touched.coApplicantInfoGovermentId ||
                formikProps.submitCount > 0) &&
                formikProps.errors.coApplicantInfoGovermentId && (
                  <div className="invalid-feedback">
                    {formikProps.errors.coApplicantInfoGovermentId}
                  </div>
                )}
            </div>

            <div className="mt-5">
              <label className="label-default">Telefonnummer</label>
              <input
                type="text"
                name="coApplicantInfoMobile"
                placeholder="Telefonnummer"
                defaultValue={formikProps.values.coApplicantInfoMobile}
                disabled={formikProps.isSubmitting}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                autoComplete="tel"
                maxLength={8}
              />
              {(formikProps.touched.coApplicantInfoMobile ||
                formikProps.submitCount > 0) &&
                formikProps.errors.coApplicantInfoMobile && (
                  <div className="invalid-feedback">
                    {formikProps.errors.coApplicantInfoMobile}
                  </div>
                )}
            </div>

            <div className="mt-5">
              <label className="label-default">E-mail</label>
              <input
                type="email"
                name="coApplicantInfoEmail"
                placeholder="E-mail"
                defaultValue={formikProps.values.coApplicantInfoEmail}
                disabled={formikProps.isSubmitting}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                autoComplete="email"
              />
              {(formikProps.touched.coApplicantInfoEmail ||
                formikProps.submitCount > 0) &&
                formikProps.errors.coApplicantInfoEmail && (
                  <div className="invalid-feedback">
                    {formikProps.errors.coApplicantInfoEmail}
                  </div>
                )}
            </div>

            <div className="mt-5">
              <label className="label-default">Ansættelsesforhold</label>
              <select
                name="coApplicantInfoEmploymentType"
                value={formikProps.values.coApplicantInfoEmploymentType}
                onChange={formikProps.handleChange}
              >
                <option value="">Vælg ansættelsesforhold</option>
                {employmentTypeOptions.map((employmentType) => (
                  <option
                    key={employmentType.value}
                    value={employmentType.value}
                  >
                    {employmentType.label}
                  </option>
                ))}
              </select>

              {(formikProps.touched.coApplicantInfoEmploymentType ||
                formikProps.submitCount > 0) &&
                formikProps.errors.coApplicantInfoEmploymentType && (
                  <div className="invalid-feedback">
                    {formikProps.errors.coApplicantInfoEmploymentType}
                  </div>
                )}
            </div>

            {isJob(formikProps.values.coApplicantInfoEmploymentType) && (
              <div className="mt-5">
                <label className="label-default">
                  Hvornår blev medansøgeren ansat i nuværende job
                </label>
                <YearSelect
                  dateOptions={DateOptions.PastDatesInclusive}
                  text="Vælg årstal"
                  value={formikProps.values.coApplicantInfoEmploymentYear?.getFullYear()}
                  onChange={(value) => {
                    const date: Date = value
                      ? new Date(Date.UTC(value, 0, 1))
                      : undefined;
                    formikProps.setFieldValue(
                      'coApplicantInfoEmploymentYear',
                      date,
                      true
                    );
                  }}
                />

                {(formikProps.touched.coApplicantInfoEmploymentYear ||
                  formikProps.submitCount > 0) &&
                  formikProps.errors.coApplicantInfoEmploymentYear && (
                    <div className="invalid-feedback">
                      {formikProps.errors.coApplicantInfoEmploymentYear}
                    </div>
                  )}
              </div>
            )}

            {/* <div className="mt-5">
              <Checkbox
                isStatic={false}
                heading="Samtykke til KreditStatus"
                checked={formikProps.values.creditStatusConsent}
                isInvalid={formikProps.values.creditStatusConsent !== undefined}
                disabled={formikProps.isSubmitting}
                onChange={(checked) => {
                  formikProps.setValues({
                    ...formikProps.values,
                    creditStatusConsent: checked,
                  });
                }}
              >
                <span className="text-xs inline-block">
                  For at kunne behandle din låneansøgning elektronisk skal vi
                  have dit samtykke til at indhente oplysninger fra
                  KreditStatus.
                  <br />
                  <br />
                  Resurs Bank må indhente oplysninger om mine lån/kreditter hos
                  andre virksomheder tilsluttet KreditStatus (se virksomhederne
                  på{' '}
                  <a
                    className="underline"
                    href="http://www.kreditstatus.dk/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.kreditstatus.dk
                  </a>
                  ). Oplysningerne, der må indhentes, er:
                  Identifikationsoplysninger, saldo, lånetype,
                  hovedstol/kreditramme og oprettelsesdato. Oplysningerne må kun
                  bruges til kreditvurdering i forbindelse med min ansøgning om
                  kredit. Det er frivilligt, om jeg ønsker at give samtykke til,
                  at Kreditgiver indsamler og behandler oplysninger om mine
                  kreditengagementer. Hvis jeg ikke ønsker at give mit samtykke
                  kan det få den betydning, at kreditværdigheden først kan
                  vurderes, når jeg selv har fremskaffet den nødvendige
                  dokumentation til Kreditgiver. Jeg kan trække mit samtykke
                  tilbage ved at kontakte Resurs Bank. Det vil dog ikke have
                  betydning for den behandling og videregivelse af mine
                  oplysninger, der er sket før samtykket trækkes tilbage. Læs
                  mere om Resurs Banks behandling af dine data{' '}
                  <a
                    className="underline"
                    href="https://www.resursbank.dk/resurs-bank-og-persondataforordningen/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    her
                  </a>
                  .
                </span>
                {(formikProps.touched.creditStatusConsent ||
                  formikProps.submitCount > 0) &&
                  formikProps.errors.creditStatusConsent && (
                    <div className="invalid-feedback">
                      {formikProps.errors.creditStatusConsent}
                    </div>
                  )}
              </Checkbox>
            </div> */}

            <div className="container-btn">
              <BackButton />

              <button
                className="btn-next"
                type="submit"
                onClick={() => formikProps.submitForm()}
              >
                Næste
              </button>
            </div>
          </div>
        )}
      </Formik>

      <div></div>
    </div>
  );
};

export default CoApplicantStep;
