import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const BackButton: React.FC<{
  overridePage?: string
}> = (props) => {
  const history = useHistory();

  const handelGoBack = () => {
    if(props.overridePage)
    {
      history.push(props.overridePage);
      return;
    }
    
    history.goBack();
  }

  return (
    <button className="btn-back mb-2" onClick={handelGoBack}>
      Tilbage
    </button>
  );
};

export { BackButton };
