import { useState } from 'react';

export enum LoadingState {
  None,
  Loading,
  Success,
  Error,
  BadRequest,
}

export function useLoading() {
  return useState(LoadingState.None);
}
