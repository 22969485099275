import React, { useContext } from 'react';
import { Step } from '../models/Step';
import { ApplicationFormStateContext } from '../state';
import { thousandNumberSeperator } from '../util';

const ProductBox: React.FunctionComponent = () => {
  const [state] = useContext(ApplicationFormStateContext);

  const doNotShownIndicator = [Step.Amount, Step.PeriodOfRepayment];

  return (
    <>
      {!doNotShownIndicator.includes(state.step) &&
        state.amount &&
        state.periodOfRepaymentOption && (
          <div
            className="px-2 py-4 sm:px-5 sm:py-7 md:max-w-2xl md:mx-auto border-white rounded mt-12 shadow-md"
            style={{ backgroundColor: '#F3FAFA' }}
          >
            <div className="flex flex-col sm:flex-row">
              <div className="flex-initial w-full sm:w-1/2 order-2 sm:order-1 p-2 mt-5 sm:mt-0">
                <div className="text-regal-darkblue font-semibold">Så nemt er det</div>
                <ol className="list-decimal ml-3 mt-2">
                  <li className="mt-2">Vælg hvor meget du vil låne. Hvis du trykker ‘andet’ kan du indstille et præcist beløb ved at "trække" i slideren</li>
                  <li className="mt-2">I boksen, kan du se detaljer om lånet, bl.a. ÅOP</li>
                  <li className="mt-2">Udfyld felterne med dine oplysninger og besvar spørgsmålene</li>
                  <li className="mt-2">Til sidst skal du bruge dit NemID for at give samtykke til eSKAT, hvorefter Resurs Bank giver dig svar på låneansøgningen med det samme. Ansøgningen tager ca. 3 min at udfylde.</li>
                  <li className="mt-2">Når du er godkendt, opretter Resurs Bank en konto til dig med lånebeløbet</li>
                </ol>
              </div>
              <div className="flex-initial w-full sm:w-1/2 order-1 sm:order-2 p-2 bg-white rounded-sm">

                <div className="flex w-full py-1 border-b border-gray-200 font-semibold text-regal-darkestblue">
                  <div className="flex-initial truncate">Løbetid</div>
                  <div className="flex-grow text-right whitespace-nowrap">
                    {state.periodOfRepaymentOption.months} mdr
                  </div>
                </div>
                <div className="flex w-full py-1 border-b border-gray-200">
                  <div className="flex-initial truncate">Udbetaling</div>
                  <div className="flex-grow text-right whitespace-nowrap">
                    {/* {state.periodOfRepaymentOption.agreementFee} kr */}0 kr
                  </div>
                </div>
                <div className="flex w-full py-1 border-b border-gray-200">
                  <div className="flex-initial truncate">Ydelse pr. måned</div>
                  <div className="flex-grow text-right whitespace-nowrap">
                    {thousandNumberSeperator(
                      state.periodOfRepaymentOption.monthlyCost
                    )}{' '}
                    kr
                  </div>
                </div>
                <div className="flex w-full py-1 border-b border-gray-200">
                  <div className="flex-initial truncate">Lånebeløb</div>
                  <div className="flex-grow text-right whitespace-nowrap">
                    {thousandNumberSeperator(state.amount)} kr
                  </div>
                </div>
                <div className="flex w-full py-1 border-b border-gray-200">
                  <div className="flex-initial truncate">Variabel debitorrente</div>
                  <div className="flex-grow text-right whitespace-nowrap">
                    {state.periodOfRepaymentOption.interest}%
                  </div>
                </div>
                <div className="flex w-full py-1 border-b border-gray-200">
                  <div className="flex-initial truncate">ÅOP</div>
                  <div className="flex-grow text-right whitespace-nowrap">
                    {state.periodOfRepaymentOption.effectiveInterest}%
                  </div>
                </div>
                <div className="flex w-full py-1 border-b border-gray-200">
                  <div className="flex-initial truncate">Kreditomkostninger</div>
                  <div className="flex-grow text-right whitespace-nowrap">
                    {thousandNumberSeperator(
                      state.periodOfRepaymentOption.totalCost - state.amount
                    )}{' '}
                    kr
                  </div>
                </div>
                <div className="flex w-full py-1 border-b border-gray-200 font-semibold text-regal-darkestblue">
                  <div className="flex-initial truncate">
                    Samlet tilbagebetalingsbeløb
                  </div>
                  <div className="flex-grow text-right whitespace-nowrap">
                    {thousandNumberSeperator(
                      state.periodOfRepaymentOption.totalCost
                    )}{' '}
                    kr
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default ProductBox;
