/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Merchant
 */
export interface Merchant {
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    merchantUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Merchant
     */
    completed?: boolean;
}

export function MerchantFromJSON(json: any): Merchant {
    return MerchantFromJSONTyped(json, false);
}

export function MerchantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Merchant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'merchantUrl': !exists(json, 'merchantUrl') ? undefined : json['merchantUrl'],
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
    };
}

export function MerchantToJSON(value?: Merchant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'merchantUrl': value.merchantUrl,
        'completed': value.completed,
    };
}


