/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationSearchItem
 */
export interface ApplicationSearchItem {
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchItem
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchItem
     */
    resursApplicationId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSearchItem
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchItem
     */
    mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchItem
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchItem
     */
    status?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSearchItem
     */
    statusAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchItem
     */
    notes?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSearchItem
     */
    notesAt?: Date | null;
}

export function ApplicationSearchItemFromJSON(json: any): ApplicationSearchItem {
    return ApplicationSearchItemFromJSONTyped(json, false);
}

export function ApplicationSearchItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationSearchItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'resursApplicationId': !exists(json, 'resursApplicationId') ? undefined : json['resursApplicationId'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusAt': !exists(json, 'statusAt') ? undefined : (json['statusAt'] === null ? null : new Date(json['statusAt'])),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'notesAt': !exists(json, 'notesAt') ? undefined : (json['notesAt'] === null ? null : new Date(json['notesAt'])),
    };
}

export function ApplicationSearchItemToJSON(value?: ApplicationSearchItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'resursApplicationId': value.resursApplicationId,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'name': value.name,
        'mobile': value.mobile,
        'email': value.email,
        'status': value.status,
        'statusAt': value.statusAt === undefined ? undefined : (value.statusAt === null ? null : value.statusAt.toISOString()),
        'notes': value.notes,
        'notesAt': value.notesAt === undefined ? undefined : (value.notesAt === null ? null : value.notesAt.toISOString()),
    };
}


