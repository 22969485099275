import React, { useEffect } from 'react';
import { trackEvent } from '../util/gtm';

const CookiePage: React.FunctionComponent = () => {
  
  useEffect(() => {
    trackEvent('Flow', 'Load', 'Cookie');
  }, []);

  return (
    <div className="textpage">
      <h2>Privatlivspolitik</h2>
      <div className="mt-2 italic">Senest opdateret 02-01-2020</div>

      <p>
        Hos Bilhandel ApS tager vi behandling af dine personoplysninger
        alvorligt, og vi sikrer en rimelig og gennemsigtig databehandling. Efter
        persondataforordningens artikel 13 og 14 skal vi give dig en række
        oplysninger, når vi behandler oplysninger om dig.
      </p>
      <p>
        Bilhandel ApS ("Bilhandel") er dataansvarlig for behandlingen af de
        personoplysninger, som vi har indsamlet om dig på vores platform,
        billaan.bilhandel.dk, ("Platformen"). Bilhandel sikrer, at oplysningerne
        bliver behandlet i overensstemmelse med gældende persondatalovgivning.
      </p>

      <h3>Kontaktoplysninger</h3>
      <p>
        Bilhandel har følgende kontaktoplysninger:
        <br />
        Bilhandel ApS
        <br />
        Adresse: Vestergade 48H, 8000 Aarhus C<br />
        CVR. nr: 42133965
        <br />
        Telefon: 69 66 38 00
        <br />
        E-mail: info@bilhandel.dk
      </p>
      <p>
        Henvendelser vedrørende behandlingen af dine personoplysninger kan
        rettes til os.
      </p>

      <h3>
        Formålene med og retsgrundlaget for behandlingen af dine
        personoplysninger
      </h3>
      <p>Vi behandler dine personoplysninger til følgende formål:</p>

      <ul>
        <li>
          Vi indsamler en række oplysninger om dig herunder navn, telefonnummer,
          CPR nummer, mm som vi gemmer og videresender til vores
          samarbejdspartner Resurs Bank A/S.
        </li>
        <li>
          Vi behandler kun dine oplysninger i det omfang, det er nødvendigt for
          at opfylde de formål, hvortil oplysningerne er indsamlet.
        </li>
      </ul>

      <p>
        Retsgrundlaget for vores behandling af dine personoplysninger er
        følgende:
      </p>
      <ul>
        <li>
          At du har samtykke til behandlingen, jf. artikel 6, stk. 1, litra a og
          art. 7, stk. 2, litra a i persondataforordningen, jf. § 6, stk. 1 og §
          11 i databeskyttelsesloven.
        </li>
        <li>
          At behandling er nødvendig af hensyn til opfyldelse af en kontrakt,
          som du er part i eller af hensyn til gennemførelse af
          foranstaltninger, der træffes på din anmodning forud for indgåelse af
          en kontrakt, jf. artikel 6, stk. 1, litra b i persondataforordningen,
          jf. § 6, stk. 1 i databeskyttelsesloven.
        </li>
        <li>
          At behandlingen ud fra hensynet til at kunne fremvise offentlig
          tilgængelig information om den handlede bil, efter en
          interesseafvejning går forud for dine grundlæggende rettigheder og
          frihedsrettigheder, jf. artikel 6, stk. 1, litra f i
          persondataforordningen, jf. § 6, stk. 1 i databeskyttelsesloven.
        </li>
      </ul>

      <p>
        Bemærk, at behandlingen af visse personoplysninger er nødvendige for at
        kunne tilbyde en normal loginprocedure samt tilvejebringe tjenesterne på
        Platformen. Uden disse oplysninger kan Platformen ikke fungere optimalt.
      </p>

      <h3>Kategorier af personoplysninger</h3>
      <p>Vi behandler følgende typer af oplysninger:</p>
      <ul>
        <li>
          Almindelige oplysninger i form af dine kontaktoplysninger, indhold
          oprettet af dig på Platformen
        </li>
        <li>CPR-nr.</li>
        <li>Email adresse</li>
        <li>Telefonnummer</li>
        <li>Civilstatus</li>
        <li>Antal hjemmeboende børn</li>
      </ul>

      <h3>Modtagere af personoplysninger</h3>
      <p>
        Vi videregiver eller overlader dine personoplysninger til følgende
        modtagere:
      </p>
      <ul>
        <li>Resurs Bank A/S</li>
      </ul>
      <p>
        Derudover videregiver vi dine personoplysninger til andre, såfremt vi er
        forpligtet hertil via lovgivning.
      </p>

      <h3>Opbevaring af oplysninger</h3>
      <p>
        Dine personoplysninger opbevares kun så længe, at det er nødvendigt af
        hensyn til at opfylde formålet, medmindre andet er påkrævet i henhold
        til lovgivningen, herunder særligt bogføringslovens regler om opbevaring
        af bogføringsmateriale i 5 år.
      </p>

      <h3>Dine rettigheder</h3>
      <p>
        Du har efter persondataforordningen en række rettigheder i forhold til
        vores behandling af oplysninger om dig. Såfremt du ønsker at gøre brug
        af disse rettigheder, som er nærmere beskrevet nedenfor, skal du
        kontakte os gennem de kontaktoplysninger, som er oplistet øverst i
        privatlivspolitikken.
      </p>
      <p>
        Vi behandler og svarer på din henvendelse så hurtigt som muligt og
        senest en måned efter, at vi har modtaget din henvendelse, medmindre
        anmodningens kompleksitet og omfang gør, at vi ikke kan svare inden for
        en måned. I så fald kan svarfristen blive op til 3 måneder i alt, jf.
        persondataforordningens artikel 12.
      </p>
      <p>
        Ret til indsigt i personoplysninger
        <br />
        Du kan på begæring til Bilhandel ApS få indsigt i hvilke persondata, som
        vi behandler om dig og hvilken behandling, der finder sted, jf.
        persondataforordningens artikel 15. Du kan navnlig få adgang til
        følgende information:
      </p>
      <ul>
        <li>Formålene med behandlingen</li>
        <li>Berørte kategorier af personoplysninger</li>
        <li>
          De modtagere eller kategorier af modtagere, som personoplysningerne er
          eller vil blive videregivet til
        </li>
        <li>
          Det påtænkte tidsrum, hvor personoplysningerne vil blive opbevaret
        </li>
        <li>
          Enhver tilgængelig information om, hvorfra personoplysningerne
          stammer, hvis de ikke indsamles hos dig
        </li>
      </ul>
      <p>
        Du kan derudover få udleveret en kopi af de personoplysninger, der
        behandles. Denne ret kan dog være begrænset af hensyn til andre
        personers privatlivsbeskyttelse, forretningshemmeligheder og til
        immaterielle rettigheder.
      </p>

      <h3>Berigtigelse eller sletning af personoplysninger</h3>
      <p>
        Du har ret til at få urigtige personoplysninger om dig selv berigtiget,
        jf. persondataforordningens artikel 16.
      </p>
      <p>
        Derudover har du ret til at få slettet personoplysninger om dig selv i
        henhold til persondataforordningens artikel 17, såfremt:
      </p>
      <ul>
        <li>
          Personoplysningerne ikke længere er nødvendige til at opfylde de
          formål, hvortil de blev indsamlet eller på anden vis behandlet;
        </li>
        <li>
          Du har trukket dit samtykke tilbage, og der ikke er et andet
          retsgrundlag for behandlingen;
        </li>
        <li>
          Du gør indsigelse mod behandlingen, og der ikke foreligger legitime
          grunde til behandlingen, som går forud for indsigelsen;
        </li>
        <li>Personoplysningerne er blevet behandlet ulovligt; eller</li>
        <li>
          Personoplysningerne skal slettes for at overholde en retlig
          forpligtelse, som vi er underlagt.
        </li>
      </ul>
      <p>
        Sletningen kan dog være begrænset i visse tilfælde. Navnlig kan sletning
        ikke ske, såfremt vi er retligt forpligtet til at opbevare alle eller
        dele af personoplysninger eller det er nødvendigt at opbevare
        personoplysningerne for, at et retskrav kan fastlægges, gøres gældende
        eller forsvares. I så fald vil vi kun opbevare de oplysninger, som vi er
        retlig forpligtet eller berettiget til og vil slette øvrige
        personoplysninger.
      </p>

      <h3>Tilbagekaldelse af samtykke</h3>
      <p>
        Du har til enhver tid ret til at trække dit samtykke til Bilhandel’s
        behandling af dine persondata helt eller delvist tilbage, jf.
        persondataforordningens artikel 7.
      </p>
      <p>
        Hvis du trækker dit samtykke, vil Bilhandel ophøre med at behandle de
        persondata, som du har tilbagekaldt dit samtykke i relation til,
        medmindre Bilhandel er retligt forpligtet eller berettiget til at
        opbevare alle eller dele af dine persondata. I så fald vil Bilhandel kun
        opbevare de oplysninger, som Bilhandel er retsligt forpligtet til, og
        vil slette øvrige persondata om dig.
      </p>
      <p>
        Din tilbagetrækning af dit samtykke berører ikke lovligheden af den
        behandling, som Bilhandel har foretaget, indtil din tilbagetrækning af
        samtykket.
      </p>

      <h3>Indsigelse</h3>
      <p>
        Du har til enhver tid ret til at gøre indsigelse imod Bilhandel’s
        behandling af dine persondata af grunde, der vedrører din særlige
        situation, såfremt behandlingen er baseret på artikel 6, stk. 1, litra f
        om interesseafvejning, jf. persondataforordningens artikel 21.
      </p>
      <p>
        I så fald må vi ikke længere behandle personoplysningerne, medmindre vi
        påviser vægtige legitime grunde til behandlingen, der går forud for dine
        interesser, rettigheder og frihedsrettigheder, eller behandlingen er
        nødvendig for, at retskrav kan fastlægges, gøres gældende eller
        forsvares.
      </p>

      <h3>Begrænsning</h3>
      <p>
        Du har ret til at få begrænset Bilhandel’s behandling af dine
        personoplysninger i følgende tilfælde, jf. persondataforordningens
        artikel 18:
      </p>

      <ul>
        <li>
          Mens Bilhandel behandler en indsigelse, som der er gjort imod
          rigtigheden af de personoplysninger, som vi behandler eller imod
          lovligheden af behandlingen. Begrænsningen af behandlingen gælder
          indtil, at vi har færdigbehandlet indsigelsen.
        </li>
        <li>
          Bilhandel’s behandling er ulovlig, men du ønsker ikke sletning af
          oplysningerne, men derimod alene at anvendelsen af personoplysningerne
          begrænses.
        </li>
        <li>
          Bilhandel har ikke længere brug for personoplysningerne til
          behandlingen, men de er nødvendige for, at et retskrav kan fastlægges,
          gøres gældende eller forsvares.
        </li>
        <li>
          Du har gjort indsigelse mod behandlingen i medfør af artikel 21, stk.
          1, i perioden mens det kontrolleres, om Bilhandel’s legitime
          interesser går forud for dine legitime interesser.
        </li>
      </ul>

      <h3>Dataportabilitet</h3>
      <p>
        Når vores behandling af dine personoplysninger baserer sig på samtykke
        eller en kontrakt, og vores behandling er foretaget automatisk, har du
        ret til dataportabilitet.
      </p>
      <p>
        Retten til dataportabilitet indebærer, at du har ret til at få tilsendt
        de persondata, som du har oplyst til os i et struktureret, almindeligt
        anvendt og maskinlæsbart format, som du har ret til at overføre til en
        anden tjenesteyder, jf. persondataforordningens artikel 20.
      </p>
      <p>
        Du kan læse mere om dine rettigheder i Datatilsynets vejledning om de
        registreredes rettigheder, som du finder på www.datatilsynet.dk
      </p>
    </div>
  );
};

export default CookiePage;
