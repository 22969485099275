import React, {
  createContext,
  useReducer,
  Dispatch,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ApplicationState } from '../models/ApplicationState';
import { Step } from '../models/Step';

const defaultState: ApplicationState = {
  step: Step.Loading,
  contactInfo: {},
  ancestralHomeland: 'DK',
  creditCardsDinersClub: false,
  creditCardsMasterCard: false,
  creditCardsPetrol: false,
  creditCardsVisa: false,
  dankort: false,
  debitCardsMasterCard: false,
};

export type ApplicationFormState = typeof defaultState;
export type ContactInfo = typeof defaultState.contactInfo;

type Action =
  | {
      type: 'set_step';
      step: Step;
    }
  | {
      type: 'set_amount';
      values: typeof defaultState.amount;
    }
  | {
      type: 'set_periodOfRepaymentOption';
      values: typeof defaultState.periodOfRepaymentOption;
    }
  | {
      type: 'set_contact_information';
      values: typeof defaultState.contactInfo;
    }
  | {
      type: 'set_consent';
      values: typeof defaultState.consent;
    }
  | {
      type: 'set_maritalStatus';
      values: typeof defaultState.maritalStatus;
    }
  | {
      type: 'set_children';
      values: typeof defaultState.children;
    }
  | {
      type: 'set_coApplicant';
      coApplicantInfoGovermentId?: string;
      coApplicantInfoMobile?: string;
      coApplicantInfoEmail?: string;
      coApplicantInfoEmploymentType?: string;
      coApplicantInfoEmploymentYear?: Date;
    }
  | {
      type: 'set_accomodationtype';
      values: typeof defaultState.accomodationType;
    }
  | {
      type: 'set_habitationyear';
      values: typeof defaultState.habitationYear;
    }
  | {
      type: 'set_govermentId';
      values: typeof defaultState.govermentId;
    }
  | {
      type: 'set_citizenship';
      values: typeof defaultState.citizenship;
    }
  | {
      type: 'set_ancestralHomeland';
      values: typeof defaultState.ancestralHomeland;
    }
  | {
      type: 'set_inDenmarkSinceYear';
      values: typeof defaultState.inDenmarkSinceYear;
    }
  | {
      type: 'set_residentPermit';
      values: typeof defaultState.residentPermit;
    }
  | {
      type: 'set_residentPermitValidDate';
      values: typeof defaultState.residentPermitValidDate;
    }
  | {
      type: 'set_memberOfAkasse';
      values: typeof defaultState.memberOfAkasse;
    }
  | {
      type: 'set_employmentType';
      values: typeof defaultState.employmentType;
    }
  | {
      type: 'set_employmentYear';
      values: typeof defaultState.employmentYear;
    }
  | {
      type: 'set_educationalLevel';
      values: typeof defaultState.educationalLevel;
    }
  | {
      type: 'set_children_group1';
      values: typeof defaultState.childrenGroup1;
    }
  | {
      type: 'set_children_group2';
      values: typeof defaultState.childrenGroup2;
    }
  | {
      type: 'set_children_group3';
      values: typeof defaultState.childrenGroup3;
    }
  | {
      type: 'set_children_group4';
      values: typeof defaultState.childrenGroup4;
    }
  | {
      type: 'set_share_of_expenses';
      values: typeof defaultState.shareOfExpenses;
    }
  | {
      type: 'set_cards';
      creditCardsDinersClub: boolean;
      creditCardsMasterCard: boolean;
      creditCardsPetrol: boolean;
      creditCardsVisa: boolean;
      dankort: boolean;
      debitCardsMasterCard: boolean;
    }
  | {
      type: 'set_resursConsent';
      values: typeof defaultState.resursConsent;
    }
  | {
      type: 'set_applicationId';
      values: typeof defaultState.applicationId;
    }
  | {
      type: 'reset_applicationData';
    };

const reducer = (
  state: ApplicationFormState,
  action: Action
): ApplicationFormState => {
  switch (action.type) {
    case 'set_step':
      return {
        ...state,
        step: action.step,
      };
    case 'set_amount':
      return {
        ...state,
        amount: action.values,
      };
    case 'set_periodOfRepaymentOption':
      return {
        ...state,
        periodOfRepaymentOption: action.values,
      };
    case 'set_consent':
      return {
        ...state,
        consent: action.values,
      };
    case 'set_contact_information':
      return {
        ...state,
        contactInfo: action.values,
      };
    case 'set_maritalStatus':
      return {
        ...state,
        maritalStatus: action.values,
      };
    case 'set_children':
      return {
        ...state,
        children: action.values,
      };
    case 'set_children_group1':
      return {
        ...state,
        childrenGroup1: action.values,
      };
    case 'set_children_group2':
      return {
        ...state,
        childrenGroup2: action.values,
      };
    case 'set_children_group3':
      return {
        ...state,
        childrenGroup3: action.values,
      };
    case 'set_children_group4':
      return {
        ...state,
        childrenGroup4: action.values,
      };
    case 'set_share_of_expenses':
      return {
        ...state,
        shareOfExpenses: action.values,
      };
    case 'set_coApplicant':
      return {
        ...state,
        coApplicantInfoGovermentId: action.coApplicantInfoGovermentId,
        coApplicantInfoMobile: action.coApplicantInfoMobile,
        coApplicantInfoEmail: action.coApplicantInfoEmail,
        coApplicantInfoEmploymentType: action.coApplicantInfoEmploymentType,
        coApplicantInfoEmploymentYear: action.coApplicantInfoEmploymentYear,
      };
    case 'set_accomodationtype':
      return {
        ...state,
        accomodationType: action.values,
      };
    case 'set_habitationyear':
      return {
        ...state,
        habitationYear: action.values,
      };
    case 'set_govermentId':
      return {
        ...state,
        govermentId: action.values,
      };
    case 'set_citizenship':
      return {
        ...state,
        citizenship: action.values,
      };
    case 'set_ancestralHomeland':
      return {
        ...state,
        ancestralHomeland: action.values,
      };
    case 'set_inDenmarkSinceYear':
      return {
        ...state,
        inDenmarkSinceYear: action.values,
      };
    case 'set_residentPermit':
      return {
        ...state,
        residentPermit: action.values,
      };
    case 'set_residentPermitValidDate':
      return {
        ...state,
        residentPermitValidDate: action.values,
      };
    case 'set_memberOfAkasse':
      return {
        ...state,
        memberOfAkasse: action.values,
      };
    case 'set_employmentType':
      return {
        ...state,
        employmentType: action.values,
      };
    case 'set_employmentYear':
      return {
        ...state,
        employmentYear: action.values,
      };
    case 'set_educationalLevel':
      return {
        ...state,
        educationalLevel: action.values,
      };
    case 'set_cards':
      return {
        ...state,
        dankort: action.dankort,
        creditCardsDinersClub: action.creditCardsDinersClub,
        creditCardsMasterCard: action.creditCardsMasterCard,
        creditCardsPetrol: action.creditCardsPetrol,
        creditCardsVisa: action.creditCardsVisa,
        debitCardsMasterCard: action.debitCardsMasterCard,
      };
    case 'set_resursConsent':
      return {
        ...state,
        resursConsent: action.values,
      };
    case 'set_applicationId':
      return {
        ...state,
        applicationId: action.values,
      };
    case 'reset_applicationData':
      return {
        ...state,
        amount: undefined,
        periodOfRepaymentOption: undefined,
        consent: undefined,
        contactInfo: undefined,
        maritalStatus: undefined,
        children: undefined,
        coApplicantInfoGovermentId: undefined,
        coApplicantInfoMobile: undefined,
        coApplicantInfoEmail: undefined,
        coApplicantInfoEmploymentType: undefined,
        coApplicantInfoEmploymentYear: undefined,
        accomodationType: undefined,
        habitationYear: undefined,
        govermentId: undefined,
        citizenship: undefined,
        inDenmarkSinceYear: undefined,
        residentPermit: undefined,
        residentPermitValidDate: undefined,
        memberOfAkasse: undefined,
        employmentType: undefined,
        employmentYear: undefined,
        educationalLevel: undefined,
        dankort: false,
        creditCardsDinersClub: false,
        creditCardsMasterCard: false,
        creditCardsPetrol: false,
        creditCardsVisa: false,
        debitCardsMasterCard: false,
        resursConsent: undefined,
        ancestralHomeland: 'DK',
      };
    default:
      throw new Error(`Case not handled in reducer`);
  }
};

export const ApplicationFormStateContext = createContext<
  [ApplicationFormState, Dispatch<Action>]
>({} as [ApplicationFormState, Dispatch<Action>]);

export const ApplicationFormStateContextProvider: React.FunctionComponent = (
  props
) => {
  const hhistory = useHistory();
  const location = useLocation();
  const [redirect] = useState(!location.search.includes('?skip-redirect=true'));
  const amount = useMemo(() => {
    if (!redirect) {
      const array = location.pathname.split('/');
      if (array.length > 0) {
        return Number(array[array.length - 1]);
      }
    } else {
      return undefined;
    }
  }, [location.pathname, redirect]);

  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    redirect,
    ...(!redirect ? { amount } : {}),
  });

  useEffect(() => {
    // redirect to frontpage on page reload
    if (
      state.amount === undefined &&
      ![
        Step.Amount,
        Step.OtherAmount,
        Step.Status,
        Step.DropOutStep,
        Step.Loading,
      ].includes(state.step) &&
      redirect
    ) {
      hhistory.push(`/`);
    }
  });

  useEffect(() => {
    //console.log('state update');
  }, [state]);

  return (
    <ApplicationFormStateContext.Provider value={[state, dispatch]}>
      {props.children}
    </ApplicationFormStateContext.Provider>
  );
};
