/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusEnum,
    StatusEnumFromJSON,
    StatusEnumFromJSONTyped,
    StatusEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetApplicationStatusResponse
 */
export interface GetApplicationStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof GetApplicationStatusResponse
     */
    continueUrl?: string | null;
    /**
     * 
     * @type {StatusEnum}
     * @memberof GetApplicationStatusResponse
     */
    status?: StatusEnum;
}

export function GetApplicationStatusResponseFromJSON(json: any): GetApplicationStatusResponse {
    return GetApplicationStatusResponseFromJSONTyped(json, false);
}

export function GetApplicationStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetApplicationStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'continueUrl': !exists(json, 'continueUrl') ? undefined : json['continueUrl'],
        'status': !exists(json, 'status') ? undefined : StatusEnumFromJSON(json['status']),
    };
}

export function GetApplicationStatusResponseToJSON(value?: GetApplicationStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'continueUrl': value.continueUrl,
        'status': StatusEnumToJSON(value.status),
    };
}


