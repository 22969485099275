import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import { trackEvent } from '../util/gtm';
import { dateFromCpr } from '../util/dates';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';
import { saveStateRemote } from './util';

const PersonalInformationStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();
  const { govermentId, citizenship, ancestralHomeland } = state;

  const ancestralHomelandOptions = [
    { value: 'DK', label: 'Danmark' },
    { value: 'FI', label: 'Finland' },
    { value: 'IS', label: 'Island' },
    { value: 'NO', label: 'Norge' },
    { value: 'SE', label: 'Sverige' },
    { value: 'AL', label: 'Albanien' },
    { value: 'AD', label: 'Andorra' },
    { value: 'BE', label: 'Belgien' },
    { value: 'BA', label: 'Bosnien-Hercegovina' },
    { value: 'BG', label: 'Bulgarien' },
    { value: 'CY', label: 'Cypern' },
    { value: 'EE', label: 'Estland' },
    { value: 'FR', label: 'Frankrig' },
    { value: 'FO', label: 'Færøerne' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GR', label: 'Grækenland' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'NL', label: 'Holland' },
    { value: 'BY', label: 'Hviderusland' },
    { value: 'IE', label: 'Irland' },
    { value: 'IM', label: 'Isle of Man' },
    { value: 'IT', label: 'Italien' },
    { value: 'JE', label: 'Jersey' },
    { value: 'HR', label: 'Kroatien' },
    { value: 'LV', label: 'Letland' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Litauen' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MT', label: 'Malta' },
    { value: 'MC', label: 'Monaco' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'PL', label: 'Polen' },
    { value: 'PT', label: 'Portugal' },
    { value: 'MK', label: 'Republikken Makedonien' },
    { value: 'MD', label: 'Republikken Moldova' },
    { value: 'RO', label: 'Rumænien' },
    { value: 'RU', label: 'Rusland' },
    { value: 'SM', label: 'San Marino' },
    { value: 'CH', label: 'Schweiz' },
    { value: 'RS', label: 'Serbien' },
    { value: 'CS', label: 'Serbien og Montenegro' },
    { value: 'SK', label: 'Slovakiet' },
    { value: 'SI', label: 'Slovenien' },
    { value: 'ES', label: 'Spanien' },
    { value: 'GB', label: 'Storbritannien' },
    { value: 'SJ', label: 'Svalbard og Jan Mayen' },
    { value: 'CZ', label: 'Tjekkiet' },
    { value: 'DE', label: 'Tyskland' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'HU', label: 'Ungarn' },
    { value: 'VA', label: 'Vatikanstaten' },
    { value: 'AX', label: 'Åland' },
    { value: 'AT', label: 'Østrig' },
    { value: 'DZ', label: 'Algeriet' },
    { value: 'AO', label: 'Angola' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'CM', label: 'Cameroun' },
    { value: 'CF', label: 'Centralafrikanske Republik' },
    { value: 'KM', label: 'Comorerne' },
    { value: 'CG', label: 'Congo' },
    { value: 'CD', label: 'Congo-Kinshasa' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'EG', label: 'Egypten' },
    { value: 'CI', label: 'Elfenbenskysten' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'ET', label: 'Etiopien' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'CV', label: 'Kap Verde' },
    { value: 'KE', label: 'Kenya' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libyen' },
    { value: 'MG', label: 'Madagaskar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'ML', label: 'Mali' },
    { value: 'MA', label: 'Marokko' },
    { value: 'MR', label: 'Mauretanien' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'RE', label: 'Reunion' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'ST', label: 'Sao Tome og Principe' },
    { value: 'SN', label: 'Senegal' },
    { value: 'SC', label: 'Seychellerne' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SO', label: 'Somalia' },
    { value: 'SH', label: 'St. Helena' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'ZA', label: 'Sydafrika' },
    { value: 'TZ', label: 'Tanzania' },
    { value: 'TD', label: 'Tchad' },
    { value: 'TG', label: 'Togo' },
    { value: 'TN', label: 'Tunesien' },
    { value: 'UG', label: 'Uganda' },
    { value: 'EH', label: 'Vestsahara' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' },
    { value: 'GQ', label: 'Ækvatorialguinea' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AG', label: 'Antigua og Barbuda' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AW', label: 'Aruba' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'BR', label: 'Brasilien' },
    { value: 'CA', label: 'Canada' },
    { value: 'KY', label: 'Caymanøerne' },
    { value: 'CL', label: 'Chile' },
    { value: 'CO', label: 'Colombia' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CU', label: 'Cuba' },
    { value: 'VI', label: 'De amerikanske jomfruøer' },
    { value: 'VG', label: 'De britiske jomfruøer' },
    { value: 'DO', label: 'Den Dominikanske Republik' },
    { value: 'DM', label: 'Dominica' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'FK', label: 'Falklandsøerne' },
    { value: 'GF', label: 'Fransk Guyana' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GL', label: 'Grønland' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'AN', label: 'Hollandske Antiller' },
    { value: 'HN', label: 'Honduras' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MX', label: 'Mexico' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'PA', label: 'Panama' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'BL', label: 'Saint Barthélemy' },
    { value: 'KN', label: 'Saint Kitts og Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'MF', label: 'Saint Martin' },
    { value: 'PM', label: 'Saint Pierre og Miquelon' },
    { value: 'VC', label: 'St. Vincent og Grenadinerne' },
    { value: 'SR', label: 'Surinam' },
    { value: 'TT', label: 'Trinidad og Tobago' },
    { value: 'TC', label: 'Turks- og Caicosøerne' },
    { value: 'US', label: 'USA' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AM', label: 'Armenien' },
    { value: 'AZ', label: 'Aserbajdsjan' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'KH', label: 'Cambodja' },
    { value: 'CY', label: 'Cypern' },
    { value: 'PS', label: 'De palæstinensiske områder' },
    { value: 'PH', label: 'Filippinerne' },
    { value: 'AE', label: 'Forenede Arabiske Emirater' },
    { value: 'GE', label: 'Georgien' },
    { value: 'IN', label: 'Indien' },
    { value: 'ID', label: 'Indonesien' },
    { value: 'IQ', label: 'Irak' },
    { value: 'IR', label: 'Iran' },
    { value: 'IL', label: 'Israel' },
    { value: 'JP', label: 'Japan' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KZ', label: 'Kasakhstan' },
    { value: 'CN', label: 'Kina' },
    { value: 'KG', label: 'Kirgisistan' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'LA', label: 'Laos' },
    { value: 'LB', label: 'Libanon' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldiverne' },
    { value: 'MN', label: 'Mongoliet' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NP', label: 'Nepal' },
    { value: 'KP', label: 'Nordkorea' },
    { value: 'OM', label: 'Oman' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'QA', label: 'Qatar' },
    { value: 'HK', label: 'SAR Hongkong' },
    { value: 'MO', label: 'SAR Macao' },
    { value: 'SA', label: 'Saudi-Arabien' },
    { value: 'SG', label: 'Singapore' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'KR', label: 'Sydkorea' },
    { value: 'SY', label: 'Syrien' },
    { value: 'TJ', label: 'Tadsjikistan' },
    { value: 'TW', label: 'Taiwan' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TL', label: 'Timor-Leste' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'TR', label: 'Tyrkiet' },
    { value: 'UZ', label: 'Usbekistan' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'YE', label: 'Yemen' },
    { value: 'AS', label: 'Amerikansk Samoa' },
    { value: 'AQ', label: 'Antarktis' },
    { value: 'AU', label: 'Australien' },
    { value: 'BV', label: 'Bouvetø' },
    { value: 'CC', label: 'Cocosøerne' },
    { value: 'CK', label: 'Cook-øerne' },
    { value: 'UM', label: 'De Mindre Amerikanske Oversøiske Øer' },
    { value: 'IO', label: 'Det Britiske Territorium i Det Indiske Ocean' },
    { value: 'FJ', label: 'Fiji-øerne' },
    { value: 'PF', label: 'Fransk Polynesien' },
    { value: 'TF', label: 'Franske Besiddelser i Det Sydlige Indiske Ocean' },
    { value: 'GU', label: 'Guam' },
    { value: 'HM', label: 'Heard- og McDonald-øerne' },
    { value: 'CX', label: 'Juleøen' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'MH', label: 'Marshalløerne' },
    { value: 'FM', label: 'Mikronesiens Forenede Stater' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NU', label: 'Niue' },
    { value: 'MP', label: 'Nordmarianerne' },
    { value: 'NF', label: 'Norfolk Island' },
    { value: 'NC', label: 'Ny Caledonien' },
    { value: 'PW', label: 'Palau' },
    { value: 'PG', label: 'Papua Ny Guinea' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'SB', label: 'Salomonøerne' },
    { value: 'WS', label: 'Samoa' },
    { value: 'GS', label: 'South Georgia og De Sydlige Sandwichøer' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'WF', label: 'Wallis og Futunaøerne' },
  ];

  useEffect(() => {
    trackEvent('Flow', 'Load', 'PersonalInformationStep');
    dispatch({ type: 'set_step', step: Step.PersonalInformation });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => {
      await saveStateRemote(state);
    };
    saveState();
  }, []);

  const validationSchema = yup.object().shape({
    govermentId: yup
      .string()
      .length(10, 'Indtast CPR-nummer (f.eks. 2412505566)')
      .matches(
        /^((3[0-1])|([1-2][0-9])|(0[1-9]))((1[0-2])|(0[1-9]))(\d{2})?([\d]{4})$/,
        'Indtast CPR-nummer (f.eks. 2412505566)'
      )
      .test('cprBeforeOtherDates', '', function (value) {
        const birth = dateFromCpr(value);

        if (birth === undefined) {
          return true;
        }

        // Check if the person 18 or over
        const today = new Date();
        const eighteen = new Date(
          birth.getFullYear(),
          birth.getMonth(),
          birth.getDate()
        );
        eighteen.setFullYear(eighteen.getFullYear() + 18);

        if (eighteen > today) {
          return this.createError({
            message:
              'For at låne penge af Resursbank skal du være myndig (mindst 18 år gammel).',
          });
        }

        // Check if habitationYear is after birth
        birth.setMonth(0);
        birth.setDate(1);

        if (state.habitationYear < birth) {
          return this.createError({
            message:
              'Obs! Du har angivet et cpr-nummer, som ikke stemmer overens med hvor længe du har oplyst at du har boet på din nuværende adresse. Tjek at begge oplysninger er korrekte og gå til næste step.',
          });
        }

        return true;
      })
      .test('cprAndCoApplicantCpr', '', function (value) {
        if (state.coApplicantInfoGovermentId) {
          if (state.coApplicantInfoGovermentId === value) {
            return this.createError({
              message:
                'Obs! Du har angivet et cpr-nummer, det er ens med din medansøgers. Tjek at begge oplysninger er korrekte og gå til næste step.',
            });
          }
        }

        return true;
      })
      .required('Indtast CPR-nummer (f.eks. 2412505566)'),
    citizenship: yup
      .string()
      .min(1, 'Vælge i hvilket land du er født')
      .required('Vælge statsborgerskab'),
    ancestralHomeland: yup
      .string()
      .min(1, 'Vælge i hvilket land du er født')
      .required('Vælge i hvilket land du er født'),
  });

  return (
    <>
      <h2>Fødselsland og CPR-nummer</h2>

      <Formik
        initialValues={{
          govermentId,
          citizenship,
          ancestralHomeland,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch({ type: 'set_govermentId', values: values.govermentId });
          dispatch({
            type: 'set_ancestralHomeland',
            values: values.ancestralHomeland,
          });

          if (state.citizenship !== 'DANISH') {
            history.push('/andet-statsborgerskab');
          } else {
            history.push('/raadighedsbeloeb');
          }
        }}
      >
        {(formikProps) => (
          <div className="mt-5">
            <div>
              <label className="label-default">
                I hvilket land du er født?
              </label>
              <select
                name="ancestralHomeland"
                value={formikProps.values.ancestralHomeland}
                onChange={formikProps.handleChange}
              >
                <option value="">Vælg i hvilket land du er født</option>
                {ancestralHomelandOptions.map((ancestralHomeland, index) => (
                  <option
                    key={ancestralHomeland.value + index}
                    value={ancestralHomeland.value}
                  >
                    {ancestralHomeland.label}
                  </option>
                ))}
              </select>

              {(formikProps.touched.ancestralHomeland ||
                formikProps.submitCount > 0) &&
                formikProps.errors.ancestralHomeland && (
                  <div className="invalid-feedback">
                    {formikProps.errors.ancestralHomeland}
                  </div>
                )}
            </div>

            <div className="mt-5">
              <label className="label-default">CPR-nummer</label>
              <input
                type="text"
                name="govermentId"
                placeholder="CPR-nummer"
                defaultValue={formikProps.values.govermentId}
                disabled={formikProps.isSubmitting}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                autoComplete="off"
                maxLength={10}
              />
              {(formikProps.touched.govermentId ||
                formikProps.submitCount > 0) &&
                formikProps.errors.govermentId && (
                  <div className="invalid-feedback">
                    {formikProps.errors.govermentId}
                  </div>
                )}
            </div>

            <div className="container-btn">
              <BackButton />
              <button
                className="btn-next"
                type="submit"
                onClick={() => formikProps.submitForm()}
              >
                Næste
              </button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default PersonalInformationStep;
