import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory, useLocation } from 'react-router-dom';
import { trackEvent } from '../util/gtm';
import { ApplicationApi } from '../lib-api';
import { ApiConfiguration } from '../api-configuration';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';

const DropOutStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const applicationId = query.get('applicationId');

  useEffect(() => {
    trackEvent("Flow", "Load", "DropOutStep");
    dispatch({ type: 'set_step', step: Step.DropOutStep });
    scrollToTopOfContent();
    
    const setFailed = async () => {
      const api = new ApplicationApi(ApiConfiguration);
      try {
            await api.setFailed({setFailedRequest: { applicationId }});
      } catch (e) {}
    }

    setFailed();
  }, []);

  const handleClick = () => {
    history.push(`/`);
  }
  
  return (
    <>
      <h2>Ansøgning afbrudt</h2>

      <div className="mt-5">
        En ansøger har forladt sessionen, og du kan derfor ikke færdiggøre ansøgningen.
      </div>

      <div className="text-center mt-4">
        <button 
          className="btn-next"
          onClick={handleClick}>Ny ansøgning</button>
      </div>

    </>
  );
};

export default DropOutStep;
