/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAverageInspectiontimeResponse
 */
export interface GetAverageInspectiontimeResponse {
    /**
     * 
     * @type {Date}
     * @memberof GetAverageInspectiontimeResponse
     */
    from?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetAverageInspectiontimeResponse
     */
    to?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof GetAverageInspectiontimeResponse
     */
    completedApplicationsAtResurs?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAverageInspectiontimeResponse
     */
    averageTotalHours?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAverageInspectiontimeResponse
     */
    averageTotalDays?: number;
}

export function GetAverageInspectiontimeResponseFromJSON(json: any): GetAverageInspectiontimeResponse {
    return GetAverageInspectiontimeResponseFromJSONTyped(json, false);
}

export function GetAverageInspectiontimeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAverageInspectiontimeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : (json['from'] === null ? null : new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (json['to'] === null ? null : new Date(json['to'])),
        'completedApplicationsAtResurs': !exists(json, 'completedApplicationsAtResurs') ? undefined : json['completedApplicationsAtResurs'],
        'averageTotalHours': !exists(json, 'averageTotalHours') ? undefined : json['averageTotalHours'],
        'averageTotalDays': !exists(json, 'averageTotalDays') ? undefined : json['averageTotalDays'],
    };
}

export function GetAverageInspectiontimeResponseToJSON(value?: GetAverageInspectiontimeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from === undefined ? undefined : (value.from === null ? null : value.from.toISOString()),
        'to': value.to === undefined ? undefined : (value.to === null ? null : value.to.toISOString()),
        'completedApplicationsAtResurs': value.completedApplicationsAtResurs,
        'averageTotalHours': value.averageTotalHours,
        'averageTotalDays': value.averageTotalDays,
    };
}


