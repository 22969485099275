/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Application,
    ApplicationFromJSON,
    ApplicationFromJSONTyped,
    ApplicationToJSON,
    StatusSource,
    StatusSourceFromJSON,
    StatusSourceFromJSONTyped,
    StatusSourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplicationStatus
 */
export interface ApplicationStatus {
    /**
     * 
     * @type {number}
     * @memberof ApplicationStatus
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    applicationId?: string;
    /**
     * 
     * @type {Application}
     * @memberof ApplicationStatus
     */
    application?: Application;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    resursApplicationId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationStatus
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    status?: string | null;
    /**
     * 
     * @type {StatusSource}
     * @memberof ApplicationStatus
     */
    source?: StatusSource;
}

export function ApplicationStatusFromJSON(json: any): ApplicationStatus {
    return ApplicationStatusFromJSONTyped(json, false);
}

export function ApplicationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'application': !exists(json, 'application') ? undefined : ApplicationFromJSON(json['application']),
        'resursApplicationId': !exists(json, 'resursApplicationId') ? undefined : json['resursApplicationId'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'source': !exists(json, 'source') ? undefined : StatusSourceFromJSON(json['source']),
    };
}

export function ApplicationStatusToJSON(value?: ApplicationStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'applicationId': value.applicationId,
        'application': ApplicationToJSON(value.application),
        'resursApplicationId': value.resursApplicationId,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'status': value.status,
        'source': StatusSourceToJSON(value.source),
    };
}


