import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ApplicationFormStateContext } from './state';
import { useHistory, useLocation } from 'react-router-dom';
import { ApiConfiguration } from '../api-configuration';
import { ApplicationApi } from '../lib-api';
import { logError } from './util';
import { trackEvent } from '../util/gtm';
import { Checkbox } from './components/Checkbox';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';

const ContactInfoStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const { contactInfo } = state;

  useEffect(() => {
    trackEvent('Flow', 'Load', 'ContactInfoStep');
    dispatch({ type: 'set_step', step: Step.ContactInfo });
    scrollToTopOfContent();
  }, []);

  const validationSchema = yup.object().shape({
    consent: yup.boolean().oneOf([true], 'Husk at acceptere vores betingelser'),
    contactInfo: yup.object({
      name: yup
        .string()
        .min(2, 'Indtast dit navn')
        .max(50, 'Indtast dit navn')
        .required('Indtast dit navn'),
      mobile: yup
        .number()
        .typeError('Indtast dit telefonnummer')
        .min(10000000, 'Indtast dit telefonnummer')
        .max(99999999, 'Indtast dit telefonnummer')
        .required('Indtast dit telefonnummer'),
      email: yup
        .string()
        .email('Indtast din e-mail')
        .required('Indtast din e-mail'),
    }),
  });

  return (
    <>
      <h2>Indtast oplysninger om dig</h2>

      {errorMessage && (
        <div className="text-center font-bold text-red-700 m-5">
          {errorMessage}
        </div>
      )}

      <Formik
        initialValues={{ consent: false, contactInfo }}
        onSubmit={async (values) => {
          dispatch({
            type: 'set_contact_information',
            values: values.contactInfo,
          });
          dispatch({ type: 'set_consent', values: values.consent });

          const api = new ApplicationApi(ApiConfiguration);
          try {
            var response = await api.initializeApplication({
              initializeApplicationRequest: {
                amount: state.amount,
                paymentMethodId: state.periodOfRepaymentOption.paymentMethodId,
                name: values.contactInfo.name,
                mobile: values.contactInfo.mobile,
                email: values.contactInfo.email,
              },
            });

            dispatch({
              type: 'set_applicationId',
              values: response.applicationId,
            });

            history.push('/civilstatus');
          } catch (e) {
            setErrorMessage(
              'Der opstod en fejl, prøv igen eller kontakt support'
            );
            logError(e);
          }
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <div className="mt-5">
            <div>
              <label className="label-default">Dit navn</label>
              <input
                type="text"
                name="contactInfo.name"
                placeholder="Dit navn"
                defaultValue={formikProps.values.contactInfo?.name}
                disabled={formikProps.isSubmitting}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                autoComplete="name"
              />

              {(formikProps.touched.contactInfo?.name ||
                formikProps.submitCount > 0) &&
                formikProps.errors.contactInfo?.name && (
                  <div className="invalid-feedback">
                    {formikProps.errors.contactInfo?.name}
                  </div>
                )}
            </div>

            <div className="mt-5">
              <label className="label-default">Dit telefonnummer</label>
              <input
                type="text"
                name="contactInfo.mobile"
                placeholder="Dit telefonnummer"
                defaultValue={formikProps.values.contactInfo?.mobile}
                disabled={formikProps.isSubmitting}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                autoComplete="tel"
                maxLength={8}
              />
              {(formikProps.touched.contactInfo?.mobile ||
                formikProps.submitCount > 0) &&
                formikProps.errors.contactInfo?.mobile && (
                  <div className="invalid-feedback">
                    {formikProps.errors.contactInfo?.mobile}
                  </div>
                )}
            </div>

            <div className="mt-5">
              <label className="label-default">Din e-mail</label>
              <input
                type="email"
                name="contactInfo.email"
                placeholder="Din e-mail"
                defaultValue={formikProps.values.contactInfo?.email}
                disabled={formikProps.isSubmitting}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                autoComplete="email"
              />
              {(formikProps.touched.contactInfo?.email ||
                formikProps.submitCount > 0) &&
                formikProps.errors.contactInfo?.email && (
                  <div className="invalid-feedback">
                    {formikProps.errors.contactInfo?.email}
                  </div>
                )}
            </div>

            <div className="mt-5">
              <div className="mr-12">
                <Checkbox
                  isStatic={true}
                  heading="Ja til betingelser"
                  checked={formikProps.values.consent}
                  isInvalid={formikProps.values.consent !== undefined}
                  onChange={(checked) => {
                    formikProps.setValues({
                      ...formikProps.values,
                      consent: checked,
                    });
                  }}
                >
                  <span className="text-xs inline-block">
                    Bilhandel ApS må opbevare og behandle personrelaterede
                    oplysninger i forbindelse med min kreditansøgning ved Resurs
                    Bank A/S. Bilhandel må kontakte mig via e-mail, SMS og
                    telefon, i relation til min kreditansøgning. Dette samtykke
                    er nødvendigt for at komme igennem ansøgningsprocessen, og
                    kan afmeldes når du har fået svar på din ansøgning.
                  </span>
                  {(formikProps.touched.consent ||
                    formikProps.submitCount > 0) &&
                    formikProps.errors.consent && (
                      <div className="invalid-feedback">
                        {formikProps.errors.consent}
                      </div>
                    )}
                </Checkbox>
              </div>
            </div>

            <div className="container-btn">
              <BackButton overridePage={`/produkt/${state.amount}`} />

              <button
                className="btn btn-next"
                type="submit"
                onClick={() => formikProps.submitForm()}
              >
                Næste
              </button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default ContactInfoStep;
