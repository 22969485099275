import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../util/gtm';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { saveStateRemote } from './util';
import { Step } from './models/Step';
import NumericPlusMinus from './components/NumericPlusMinus';

const ChildrenStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();

  useEffect(() => {
    trackEvent('Flow', 'Load', 'ChildrenStep');
    dispatch({ type: 'set_step', step: Step.Children });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => {
      await saveStateRemote(state);
    };
    saveState();
  }, []);

  const handleNext = () => {
    history.push('/tilfoej-medansoeger');
  };

  const handleChange = (value: number, key: string) => {
    if (key === 'group1') {
      dispatch({ type: 'set_children_group1', values: value });
    } else if (key === 'group2') {
      dispatch({ type: 'set_children_group2', values: value });
    } else if (key === 'group3') {
      dispatch({ type: 'set_children_group3', values: value });
    } else if (key === 'group4') {
      dispatch({ type: 'set_children_group4', values: value });
    }
  };

  const handleProcentChange = (value: number) => {
    dispatch({ type: 'set_share_of_expenses', values: value });
  };

  return (
    <>
      <h2>Antal hjemmeboende børn?</h2>
      <h3>Hvis du har hjemmeboende børn skal det angives her</h3>

      <div className="mt-5">
        <NumericPlusMinus
          defaultValue={state.childrenGroup1 ? state.childrenGroup1 : 0}
          step={1}
          min={0}
          max={10}
          label="Børn mellem 0 - 1 år"
          onChange={(value) => handleChange(value, 'group1')}
        />

        <div className="mt-5">
          <NumericPlusMinus
            defaultValue={state.childrenGroup2 ? state.childrenGroup2 : 0}
            step={1}
            min={0}
            max={10}
            label="Børn mellem 2 - 6 år"
            onChange={(value) => handleChange(value, 'group2')}
          />
        </div>

        <div className="mt-5">
          <NumericPlusMinus
            defaultValue={state.childrenGroup3 ? state.childrenGroup3 : 0}
            step={1}
            min={0}
            max={10}
            label="Børn mellem 7 - 17 år"
            onChange={(value) => handleChange(value, 'group3')}
          />
        </div>

        <div className="mt-5">
          <NumericPlusMinus
            defaultValue={state.childrenGroup4 ? state.childrenGroup4 : 0}
            step={1}
            min={0}
            max={10}
            label="Børn 18 år eller ældre"
            onChange={(value) => handleChange(value, 'group4')}
          />
        </div>
      </div>

      <div className="mt-5">
        <NumericPlusMinus
          defaultValue={state.shareOfExpenses ? state.shareOfExpenses : 0}
          step={10}
          min={0}
          max={100}
          label="Hvor stor en del af husstandens samlede udgifter betaler du (%)"
          onChange={handleProcentChange}
        />
      </div>

      <div className="container-btn">
        <BackButton />
        <button className="btn btn-next" onClick={handleNext}>
          Næste
        </button>
      </div>
    </>
  );
};

export default ChildrenStep;
