/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationData
 */
export interface ApplicationData {
    /**
     * 
     * @type {string}
     * @memberof ApplicationData
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationData
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationData
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationData
     */
    name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationData
     */
    createdDate?: Date;
}

export function ApplicationDataFromJSON(json: any): ApplicationData {
    return ApplicationDataFromJSONTyped(json, false);
}

export function ApplicationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
    };
}

export function ApplicationDataToJSON(value?: ApplicationData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'email': value.email,
        'status': value.status,
        'name': value.name,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
    };
}


