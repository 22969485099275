import React, { useContext, useEffect, useState } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import ButtonSelect from './components/ButtonSelect';
import { trackEvent } from '../util/gtm';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';
import { saveStateRemote } from './util';

const MaritalStatusStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();
  
  useEffect(() => {
    trackEvent("Flow", "Load", "MaritalStatusStep");
    dispatch({ type: 'set_step', step: Step.Maritalstatus });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => { await saveStateRemote(state); }
    saveState();
  }, []);

  const maritalStatusOptions = [
    { value: 'SINGLE', label: 'Alene' },
    { value: 'MARRIED', label: 'Gift' },
    { value: 'COHABITING', label: 'Samlevende' },
    { value: 'DIVORCED', label: 'Skilt' },
    { value: 'SEPERATED', label: 'Separeret' },
    { value: 'WIDOW', label: 'Enke/enkemand' },
  ];

  const onOptionSelected = (option) => {
    dispatch({type: 'set_maritalStatus', values: option.value });
    history.push('/boern');
  };

  return (
    <>
      <h2>Hvad er din nuværende civilstatus?</h2>

      <div className="mt-5">
        <ButtonSelect
          options={maritalStatusOptions}
          onOptionSelected={(option) => onOptionSelected(option)}
          option={undefined}
          getOptionText={(option) => {
            return option.label;
          }}
          getOptionKey={(option) => {
            return option.value;
          }}
        ></ButtonSelect>
      </div>
      <div className="container-btn">
        <BackButton />
      </div>
    </>
  );
};

export default MaritalStatusStep;
