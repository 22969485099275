import React, { useState } from "react";
import CheckmarkIcon from "./CheckmarkIcon";

const Checkbox: React.FC<{
  checked: boolean;
  disabled?: boolean;
  isInvalid?: boolean;
  heading: string;
  isStatic: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
}> = (props) => {
  return (
    <div
      onClick={() => !props.disabled && props.onChange(!props.checked)}
      className={
        "cursor-pointer cover-checkbox" +
        (props.checked ? " checked" : "") +
        (props.disabled ? " disabled" : "") +
        (props.isInvalid ? " is-invalid" : "")
      }
    >
      <div className="flex">
        
        <div className={`checkmark ${props.checked ? "checked" : ""} flex-none w-4 h-4 mt-1`}>
          {props.checked && <CheckmarkIcon />}
        </div>

        <div className="flex-initial ml-2">
          <div>{props.heading}</div>
          {(!props.checked || props.isStatic) && 
            <>
            {props.children}
            </>
          }
          
        </div>
      </div>
    </div>
  );
};

export { Checkbox };
