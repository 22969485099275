import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import { ApiConfiguration } from '../api-configuration';
import { ApplicationApi } from '../lib-api';
import { logError, saveStateRemote } from './util';
import { trackEvent } from '../util/gtm';
import { Checkbox } from './components/Checkbox';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';

const ConditionsStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    trackEvent('Flow', 'Load', 'ConditionsStep');
    dispatch({ type: 'set_step', step: Step.ConditionsStep });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => {
      await saveStateRemote(state);
    };
    saveState();
  }, []);

  const validationSchema = yup.object().shape({
    resursConsent: yup.boolean().oneOf([true], 'Husk at acceptere betingelser'),
    // creditStatusConsent: yup
    //   .boolean()
    //   .oneOf([true], 'Husk at acceptere samtykke til KreditStatus'),
  });

  return (
    <>
      <h2>Godkend ansøgning</h2>

      <h3>
        Du er nu klar til at godkende ansøgningen og du skal have dit NemID
        parat og godkende hos eSKAT. Hvis du har valgt en medansøger skal
        vedkommende også have sit NemID parat.
      </h3>

      {errorMessage && (
        <div className="text-center font-bold text-red-700 mt-5">
          {errorMessage}
        </div>
      )}

      <Formik
        initialValues={{
          // creditStatusConsent: false,
          resursConsent: false,
        }}
        onSubmit={async (values) => {
          dispatch({ type: 'set_resursConsent', values: values.resursConsent });

          const api = new ApplicationApi(ApiConfiguration);
          try {
            await api.startApplication({
              startApplicationRequest: {
                applicationId: state.applicationId,
                maritalStatus: state.maritalStatus,
                children: state.children,
                coApplicantInfoGovermentId: state.coApplicantInfoGovermentId,
                coApplicantInfoMobile: state.coApplicantInfoMobile,
                coApplicantInfoEmail: state.coApplicantInfoEmail,
                coApplicantInfoEmploymentType:
                  state.coApplicantInfoEmploymentType,
                coApplicantInfoEmploymentYear:
                  state.coApplicantInfoEmploymentYear,
                accomodationType: state.accomodationType,
                habitationYear: state.habitationYear,
                govermentId: state.govermentId,
                citizenship: state.citizenship,
                ancestralHomeland: state.ancestralHomeland,
                inDenmarkSinceYear: state.inDenmarkSinceYear,
                residentPermit: state.residentPermit,
                residentPermitValidDate: state.residentPermitValidDate,
                memberOfAkasse: state.memberOfAkasse,
                employmentType: state.employmentType,
                employmentYear: state.employmentYear,
                educationalLevel: state.educationalLevel,
                dankort: state.dankort,
                debitCardsMasterCard: state.debitCardsMasterCard,
                creditCardsMasterCard: state.creditCardsMasterCard,
                creditCardsVisa: state.creditCardsVisa,
                creditCardsDinersClub: state.creditCardsDinersClub,
                creditCardsPetrol: state.creditCardsPetrol,
              },
            });
            trackEvent('Flow', 'Goto', 'eSkat');
            history.push(`/status?applicationId=${state.applicationId}`);
          } catch (e) {
            setErrorMessage(
              'Der opstod en fejl, prøv igen eller kontakt support'
            );
            logError(e);
          }
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <div>
            <div className="mt-5">
              <Checkbox
                isStatic={false}
                heading="Samtykke til betingelser"
                checked={formikProps.values.resursConsent}
                isInvalid={formikProps.values.resursConsent !== undefined}
                disabled={formikProps.isSubmitting}
                onChange={(checked) => {
                  formikProps.setValues({
                    ...formikProps.values,
                    resursConsent: checked,
                  });
                }}
              >
                <span className="text-xs inline-block">
                  Ved tegning af aftale med Resurs Bank indgår du i
                  Resurskoncernens kunderegister. Det primære formål med vores
                  behandling af personoplysninger er at indsamle, verificere og
                  registrere de oplysninger, der kræves for at indgå en aftale
                  med dig, samt at dokumentere, administrere og fuldføre
                  aftaler. Personoplysninger kan også behandles som grundlag for
                  markedsføring. Når behandlingen er til markedsføringsformål,
                  kan profilering også forekomme for at målrette tilpassede
                  tilbud til dig, hvorved du kan tilbydes alternative produkter
                  eller kontaktes af Resurs Banks samarbejdspartnere. Fuld
                  information om vores behandling af personoplysninger og dine
                  rettigheder finder du i vores vilkår for behandling af
                  personoplysninger.
                  <br />
                  <br />
                  Jeg er indforstået med at jeg, ved at kontakte Resurs Bank,
                  kan jeg anmode om en spærring for direkte markedsføring. Jeg
                  forsikrer, at de indsendte oplysninger er korrekte og
                  fuldstændige og tillader, at ansøgningen kreditprøves, hvorved
                  arbejdsgiveren kan blive kontaktet. Resurs Bank forbeholder
                  sig fri prøvningsret. Jeg forsikrer endvidere, at jeg før min
                  ansøgning har modtaget standardiseret europæisk
                  forbrugerkreditinformation (SEKKI) og Resurs Banks generelle
                  vilkår for privat konto- og kortkredit samt privatlån for
                  Danmark, hvis bestemmelser hermed accepteres.
                </span>
                {(formikProps.touched.resursConsent ||
                  formikProps.submitCount > 0) &&
                  formikProps.errors.resursConsent && (
                    <div className="invalid-feedback">
                      {formikProps.errors.resursConsent}
                    </div>
                  )}
              </Checkbox>
            </div>

            {/* <div className="mt-5">
              <Checkbox
                isStatic={false}
                heading="Samtykke til KreditStatus"
                checked={formikProps.values.creditStatusConsent}
                isInvalid={formikProps.values.creditStatusConsent !== undefined}
                disabled={formikProps.isSubmitting}
                onChange={(checked) => {
                  formikProps.setValues({
                    ...formikProps.values,
                    creditStatusConsent: checked,
                  });
                }}
              >
                <span className="text-xs inline-block">
                  For at kunne behandle din låneansøgning elektronisk skal vi
                  have dit samtykke til at indhente oplysninger fra
                  KreditStatus.
                  <br />
                  <br />
                  Resurs Bank må indhente oplysninger om mine lån/kreditter hos
                  andre virksomheder tilsluttet KreditStatus (se virksomhederne
                  på{' '}
                  <a
                    className="underline"
                    href="http://www.kreditstatus.dk/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.kreditstatus.dk
                  </a>
                  ). Oplysningerne, der må indhentes, er:
                  Identifikationsoplysninger, saldo, lånetype,
                  hovedstol/kreditramme og oprettelsesdato. Oplysningerne må kun
                  bruges til kreditvurdering i forbindelse med min ansøgning om
                  kredit. Det er frivilligt, om jeg ønsker at give samtykke til,
                  at Kreditgiver indsamler og behandler oplysninger om mine
                  kreditengagementer. Hvis jeg ikke ønsker at give mit samtykke
                  kan det få den betydning, at kreditværdigheden først kan
                  vurderes, når jeg selv har fremskaffet den nødvendige
                  dokumentation til Kreditgiver. Jeg kan trække mit samtykke
                  tilbage ved at kontakte Resurs Bank. Det vil dog ikke have
                  betydning for den behandling og videregivelse af mine
                  oplysninger, der er sket før samtykket trækkes tilbage. Læs
                  mere om Resurs Banks behandling af dine data{' '}
                  <a
                    className="underline"
                    href="https://www.resursbank.dk/resurs-bank-og-persondataforordningen/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    her
                  </a>
                  .
                </span>
                {(formikProps.touched.creditStatusConsent ||
                  formikProps.submitCount > 0) &&
                  formikProps.errors.creditStatusConsent && (
                    <div className="invalid-feedback">
                      {formikProps.errors.creditStatusConsent}
                    </div>
                  )}
              </Checkbox>
            </div> */}

            <div className="container-btn">
              <BackButton />

              <button
                className="btn-next"
                style={{ width: '170px' }}
                type="submit"
                onClick={() => formikProps.submitForm()}
              >
                Ok, gå til eSKAT
              </button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default ConditionsStep;
