import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { ApplicationApi, GetStatusesResponse } from '../../lib-api';
import { ApiConfiguration } from '../../api-configuration';

const TaskStatuses: React.FunctionComponent = () => {
  const [status, setStatus] = useState<GetStatusesResponse | undefined>(
    undefined
  );
  const qApplicationId =
    new URLSearchParams(window.location.search).get('applicationid') ?? '';

  const getApplcationStatus = async (applicationId: string) => {
    const api = new ApplicationApi(ApiConfiguration);
    try {
      var response = await api.getStatuses({
        getStatusesRequest: { applicationId },
      });
      setStatus(response);
    } catch (e) {
      setStatus(undefined);
    }
  };

  useEffect(() => {
    getApplcationStatus(qApplicationId);
  }, [qApplicationId]);

  return (
    <>
      <p>
        <strong>Application status</strong>
      </p>
      <Formik
        initialValues={{ applicationId: qApplicationId }}
        onSubmit={async (values) => {
          await getApplcationStatus(values.applicationId);
        }}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <div className="mt-5">
              <div>
                <label className="label-default">Application ID</label>
                <input
                  type="text"
                  name="applicationId"
                  placeholder="Indtast application id"
                  defaultValue={formikProps.values.applicationId}
                  disabled={formikProps.isSubmitting}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                />
              </div>

              {status?.status && (
                <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Status
                    </h3>
                  </div>
                  <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Resurs ID
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {status.status.resursApplicationId ?? '-'}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Status
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {status.status.status ?? '-'}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              )}

              {status?.subStatuses && (
                <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Substatus
                    </h3>
                  </div>
                  <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Gennemført
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {status.subStatuses.merchant?.completed
                            ? 'JA'
                            : 'NEJ'}
                          {status.subStatuses.merchant?.completed === false && (
                            <span className="ml-2">
                              &#40;
                              <a
                                href={status.subStatuses.merchant!.merchantUrl!}
                                target="_blank"
                                rel="noreferrer"
                              >
                                fortsæt her...
                              </a>{' '}
                              &#41;
                            </span>
                          )}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Ansøger status
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {status.subStatuses.customer?.customerTaskStatus ??
                            '-'}
                          {status.subStatuses.customer?.customerUrl && (
                            <span className="ml-2">
                              &#40;
                              <a
                                href={status.subStatuses.customer!.customerUrl!}
                                target="_blank"
                                rel="noreferrer"
                              >
                                fortsæt her...
                              </a>{' '}
                              &#41;
                            </span>
                          )}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Medansøger status
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {status.subStatuses.coCustomer?.customerTaskStatus ??
                            '-'}
                          {status.subStatuses.coCustomer?.customerUrl && (
                            <span className="ml-2">
                              &#40;
                              <a
                                href={
                                  status.subStatuses.coCustomer!.customerUrl!
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                fortsæt her...
                              </a>{' '}
                              &#41;
                            </span>
                          )}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              )}

              <div className="container-btn">
                <button className="btn btn-next" type="submit">
                  Hent status
                </button>
                {/* <input type="submit" /> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default TaskStatuses;
