/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StatusSource {
    Job = 'Job',
    SetFailed = 'SetFailed',
    StartApplication = 'StartApplication',
    GetApplicationStatus = 'GetApplicationStatus'
}

export function StatusSourceFromJSON(json: any): StatusSource {
    return StatusSourceFromJSONTyped(json, false);
}

export function StatusSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusSource {
    return json as StatusSource;
}

export function StatusSourceToJSON(value?: StatusSource | null): any {
    return value as any;
}

