import { parse } from 'date-fns';

export function dateFromCpr(input:string) {

    if(!input || input.length < 6)
    {
      return undefined;
    }
  
    try
    {
      const today = new Date();
      const sDate = input.substring(0,2);
      const sMonth = input.substring(2,4);
      let year = parseInt(input.substring(4,6));
  
      if(year > today.getFullYear() % 1000) {
        year += 1900;
      } else {
        year += 2000;
      }
  
      var parsedDate = parse(`${sDate}${sMonth}${year}`, 'ddMMyyyy', today);
      if(parsedDate instanceof Date && !isNaN(parsedDate.getTime()))
      {
        return parsedDate;
      }
    }
    catch { }
  
    return undefined;
  }