import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { trackEvent } from '../util/gtm';
import { ApiConfiguration } from '../api-configuration';
import { ApplicationApi } from '../lib-api';
import { LoadingState, useLoading } from './hooks/loading';
import { Loading } from './components/Loading';

const StatusStep: React.FunctionComponent = () => {
  const { paymentid } = useParams();
  const [loadingState, setLoadingState] = useLoading();
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const amount = +query.get('amount');

  useEffect(() => {
    trackEvent('Flow', 'Load', 'Product');
    dispatch({ type: 'reset_applicationData' });
    
    setLoadingState(LoadingState.Loading);

    const getOptions = async () => {
      
      try {
        const api = new ApplicationApi(ApiConfiguration);
        const response = await api.getPeriodOfRepaymentOptions({ amount });

        const periodOfRepaymentOption = response.find(x => x.paymentMethodId === paymentid);
        if(periodOfRepaymentOption)
        {
          setLoadingState(LoadingState.Success);
          
          dispatch({ type: 'set_amount', values: amount });
          dispatch({
            type: 'set_periodOfRepaymentOption',
            values: periodOfRepaymentOption,
          });
          
          history.push(`/kunde`);
          return;
        }
        history.push(`/`);
      }
      catch { }
      history.push(`/`);
    }
    
    getOptions();
  }, [paymentid]);

  return (
    <>
      <h2>Vent venligst</h2>

      {loadingState === LoadingState.Loading && (
        <div className="mt-4">
          <Loading text="Henter produkt...." />
        </div>
      )}


    </>
  );
};

export default StatusStep;