/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationSearchItem,
    ApplicationSearchItemFromJSON,
    ApplicationSearchItemFromJSONTyped,
    ApplicationSearchItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplicationSearchResponse
 */
export interface ApplicationSearchResponse {
    /**
     * 
     * @type {Array<ApplicationSearchItem>}
     * @memberof ApplicationSearchResponse
     */
    items?: Array<ApplicationSearchItem> | null;
}

export function ApplicationSearchResponseFromJSON(json: any): ApplicationSearchResponse {
    return ApplicationSearchResponseFromJSONTyped(json, false);
}

export function ApplicationSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ApplicationSearchItemFromJSON)),
    };
}

export function ApplicationSearchResponseToJSON(value?: ApplicationSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ApplicationSearchItemToJSON)),
    };
}


