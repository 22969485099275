import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PeriodOfRepaymentOption } from "../lib-api";
import { usePeriodOfRepaymentOptions } from "./hooks/periodOfRepaymentOptions";
import { ApplicationFormStateContext } from "./state";
import { useHistory } from "react-router-dom";
import { LoadingState } from "./hooks/loading";
import { saveStateRemote, thousandNumberSeperator } from "./util";
import { Loading } from "./components/Loading";
import { trackEvent } from "../util/gtm";
import { BackButton } from "./components/BackButton";
import { scrollToTopOfContent } from "./features/scroll";
import { Step } from "./models/Step";

const PeriodOfRepaymentStep: React.FunctionComponent = () => {
  const maxIntialVisibleOptions = 8;
  let { amount } = useParams();
  let [showAll, setShowAll] = useState(false);

  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const {
    periodOfRepaymentOptions,
    periodOfRepaymentOptionsState,
  } = usePeriodOfRepaymentOptions(amount);
  const history = useHistory();

  useEffect(() => {
    trackEvent("Flow", "Load", "PeriodOfRepaymentStep");
    dispatch({ type: "set_step", step: Step.PeriodOfRepayment });
    scrollToTopOfContent();
  }, []);

  useEffect(() => {}, [amount]);

  useEffect(() => {}, [periodOfRepaymentOptions]);

  const handelClick = (periodOfRepaymentOption: PeriodOfRepaymentOption) => {
    dispatch({
      type: "set_periodOfRepaymentOption",
      values: periodOfRepaymentOption,
    });
    history.push(`/kunde`);
  };

  const changeAmount = useCallback(() => {
    history.replace("/");
  }, [history]);

  return (
    <>
      <h2>Vælg din månedlige ydelse og løbetid</h2>
      <h3>
        Dit valgte kreditbeløb:{" "}
        <span className="whitespace-nowrap font-semibold">
          {thousandNumberSeperator(amount)} kr.
        </span>{" "}
        <button className="text-regal-dark underline" onClick={changeAmount}>
          ændre beløb
        </button>
      </h3>

      {periodOfRepaymentOptionsState === LoadingState.Loading && (
        <div className="m-5">
          <Loading text="Henter lånemuligheder..." />
        </div>
      )}

      {periodOfRepaymentOptionsState === LoadingState.Success && (
        <>
          <div className="mt-5">
            {periodOfRepaymentOptions.length === 0 && (
              <div className="text-center font-bold text-red-700 m-5">
                Vi kan desværre ikke tilbyde lån for dette kreditbeløb.
              </div>
            )}

            {periodOfRepaymentOptions.length > 0 && (
              <div
                className="w-1/2 md:w-1/3 p-2 text-center"
                style={{ marginTop: "-30px" }}
              >
                <div className="inline-block">
                  <div
                    className="relative text-sm md:text-base rounded px-1 inline-block text-white shadow-xl bg-blue-400"
                    style={{ top: "30px" }}
                  >
                    Mest populære
                  </div>
                </div>
              </div>
            )}

            {periodOfRepaymentOptions.map((periodOfRepaymentOption, index) => (
              <div key={`more_${index}`} className="inline">
                {periodOfRepaymentOptions.length >
                  maxIntialVisibleOptions + 1 &&
                  index === maxIntialVisibleOptions &&
                  !showAll && (
                    <div className="w-full md:w-1/3 inline-block p-2 self-end">
                      <button
                        className="w-full text-regal-darkestblue"
                        onClick={() => setShowAll(true)}
                        title="se resten"
                      >
                        <div className="rounded-t-md p-5">
                          <div className="font-semibold">&nbsp;</div>
                          <div className="font-semibold whitespace-nowrap mt-2">
                            vis flere muligheder
                          </div>
                          <div className="sm:text-base font-semibold mt-1">
                            ...
                          </div>
                        </div>
                      </button>
                    </div>
                  )}

                {(index < maxIntialVisibleOptions ||
                  showAll ||
                  periodOfRepaymentOptions.length ===
                    maxIntialVisibleOptions + 1) && (
                  <div className="w-1/2 md:w-1/3 inline-block p-2 text-white">
                    <button
                      className="w-full border border-gray-800 rounded shadow-md bg-regal-darkblue"
                      onClick={() => handelClick(periodOfRepaymentOption)}
                    >
                      <div className="rounded-t-md p-5">
                        <div className="font-semibold">Pris pr måned</div>
                        <div className="text-2xl sm:text-3xl font-semibold whitespace-nowrap mt-2">
                          {thousandNumberSeperator(
                            periodOfRepaymentOption.monthlyCost
                          )}
                          <span className="text-sm sm:text-xl"> kr.</span>
                        </div>
                        <div className="sm:text-lg font-semibold mt-1">
                          {periodOfRepaymentOption.months} mdr.
                        </div>
                      </div>
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="container-btn">
            <BackButton overridePage="/" />
          </div>
        </>
      )}
    </>
  );
};

export default PeriodOfRepaymentStep;
