export enum Step {
  Loading,
  AccomodationType,
  Alternatives,
  Amount,
  OtherAmount,
  Cards,
  Children,
  CitizenshipNonDanish,
  CoApplicant,
  ContactInfo,
  HabitationYear,
  HasCoApplicant,
  Maritalstatus,
  MemberOfAkasse,
  MonthlyAmountAfterExpenses,
  PeriodOfRepayment,
  PersonalInformation,
  Status,
  ConditionsStep,
  CitizenshipStep,
  DropOutStep,
}
