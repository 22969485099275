import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import {
  AdminApi,
  ApplicationSearchResponse,
  Configuration,
} from '../../lib-api';
import { ApiConfiguration } from '../../api-configuration';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const AdminApplicationSearch: React.FunctionComponent = () => {
  const [all, setAll] = useState<boolean>(true);
  const [searching, setSearching] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [searchResult, setSearchResult] = useState<
    ApplicationSearchResponse | undefined
  >(undefined);

  useEffect(() => {
    const loadStatuses = async () => {
      const api = new AdminApi(ApiConfiguration);
      await api.statuses().then((statuses) => {
        setStatuses(statuses);
      });
    };

    loadStatuses();
  }, []);

  if (!window.sessionStorage.getItem('apikey')) {
    window.location.href = '/admin/login';
    return <></>;
  }

  const authConfiguration = new Configuration({
    basePath: ApiConfiguration.basePath,
    headers: {
      'X-ApiKey': window.sessionStorage.getItem('apikey') ?? '',
    },
  });

  const formatDate = (date: Date | undefined | null): string => {
    if (!date) {
      return '';
    }
    return format(date!, 'dd/MM/yyyy');
  };

  const formatTime = (date: Date | undefined | null): string => {
    if (!date) {
      return '';
    }
    return format(date!, 'HH:mm');
  };

  const handleAddNotes = async (applicationId: string) => {
    const notes = prompt('Indtast noter:');
    if (notes && notes.length > 0) {
      const api = new AdminApi(authConfiguration);
      await api
        .addApplicationNotes({
          addApplicationNotesRequest: {
            applicationId,
            notes,
          },
        })
        .then(() => {
          toast.success('Noter gemt 🦄');
        });
    }
  };

  return (
    <>
      <p>
        <strong>Ansøgninger</strong>
      </p>

      <Formik
        initialValues={{ query: '', status: '' }}
        onSubmit={async (values) => {
          setSearching(true);
          const api = new AdminApi(authConfiguration);
          await api
            .applicationSearch({
              applicationSearchRequest: {
                query: values.query,
                status: values.status,
              },
            })
            .then((result) => {
              setAll(values.status.length === 0);
              setSearchResult(result);
            })
            .finally(() => setSearching(false));
        }}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <div className="mt-5">
              <div className="flex">
                <div className="flex">
                  <select
                    style={{ width: 'initial' }}
                    name="status"
                    defaultValue={formikProps.values.status}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                  >
                    <option value={''}>Alle</option>
                    {statuses.map((s) => {
                      return (
                        <option key={s} value={s}>
                          {s}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="ml-2 flex flex-1">
                  <input
                    style={{ width: '100%' }}
                    type="text"
                    name="query"
                    placeholder="Navn / mobil / e-mail"
                    defaultValue={formikProps.values.query}
                    disabled={formikProps.isSubmitting}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                  />
                </div>

                <div className="flex justify-end">
                  <button
                    className="btn btn-next"
                    type="submit"
                    disabled={searching}
                  >
                    {searching ? 'Henter...' : 'Vis'}
                  </button>
                </div>
              </div>

              <div className="mt-5">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      {all && (
                        <th
                          scope="col"
                          className="px-2 py-3 text-left"
                          style={{ width: '150px' }}
                        >
                          Status
                        </th>
                      )}
                      <th
                        scope="col"
                        className="px-2 py-3 text-left"
                        style={{ width: '100px' }}
                      >
                        Statusdato
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left"
                        style={{ width: '100px' }}
                      >
                        Oprettet
                      </th>
                      <th scope="col" className="px-2 py-3 text-left">
                        Navn
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left"
                        style={{ width: '150px' }}
                      >
                        Tlf.nr.
                      </th>
                      <th scope="col" className="px-2 py-3 text-left">
                        Noter
                      </th>
                      <th style={{ width: '65px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResult?.items?.map((item) => {
                      return (
                        <tr
                          key={item.applicationId}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 "
                        >
                          {all && (
                            <td
                              className="px-2 py-4"
                              title={`${formatDate(item.statusAt)} ${formatTime(
                                item.statusAt
                              )}`}
                            >
                              {item.status}
                            </td>
                          )}
                          <td
                            title={formatTime(item.statusAt)}
                            className="px-2 py-4"
                          >
                            {formatDate(item.statusAt)}
                          </td>
                          <td
                            title={formatTime(item.created)}
                            className="px-2 py-4"
                          >
                            {formatDate(item.created)}
                          </td>
                          <td className="px-2 py-4">{item.name}</td>
                          <td className="px-2 py-4">{item.mobile}</td>
                          <td
                            title={`${formatDate(item.notesAt)} ${formatTime(
                              item.notesAt
                            )}`}
                            className="px-2 py-4"
                          >
                            {item.notes && (
                              <>
                                {formatDate(item.notesAt)}: {item.notes}
                              </>
                            )}
                          </td>
                          <td className="px-2 py-4 text-right">
                            <div>
                              <svg
                                className="inline cursor-pointer"
                                onClick={() =>
                                  handleAddNotes(item.applicationId!)
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                height={15}
                                width={15}
                                viewBox="0 0 512 512"
                              >
                                <title>Tilføj noter</title>
                                <path d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z" />
                              </svg>

                              <Link
                                to={`/admin/taskstatuses?applicationid=${item.applicationId}`}
                                target="_blank"
                              >
                                <svg
                                  className="inline ml-2 cursor-pointer"
                                  xmlns="http://www.w3.org/2000/svg"
                                  height={15}
                                  width={15}
                                  viewBox="0 0 512 512"
                                >
                                  <title>Live status</title>
                                  <path d="M80 104a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm80-24c0 32.8-19.7 61-48 73.3v87.8c18.8-10.9 40.7-17.1 64-17.1h96c35.3 0 64-28.7 64-64v-6.7C307.7 141 288 112.8 288 80c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V160c0 70.7-57.3 128-128 128H176c-35.3 0-64 28.7-64 64v6.7c28.3 12.3 48 40.5 48 73.3c0 44.2-35.8 80-80 80s-80-35.8-80-80c0-32.8 19.7-61 48-73.3V352 153.3C19.7 141 0 112.8 0 80C0 35.8 35.8 0 80 0s80 35.8 80 80zm232 0a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zM80 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
                                </svg>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AdminApplicationSearch;
