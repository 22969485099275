/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetStatusRequest
 */
export interface GetStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof GetStatusRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRequest
     */
    successUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRequest
     */
    failedUrl?: string | null;
}

export function GetStatusRequestFromJSON(json: any): GetStatusRequest {
    return GetStatusRequestFromJSONTyped(json, false);
}

export function GetStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'successUrl': !exists(json, 'successUrl') ? undefined : json['successUrl'],
        'failedUrl': !exists(json, 'failedUrl') ? undefined : json['failedUrl'],
    };
}

export function GetStatusRequestToJSON(value?: GetStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'successUrl': value.successUrl,
        'failedUrl': value.failedUrl,
    };
}


