import React from 'react';

export enum DateOptions {
  FutureDatesInclusive,
  PastDatesInclusive,
}

function YearSelect(
  props: React.PropsWithChildren<{
    dateOptions: DateOptions;
    text?: string;
    value?: number;
    onChange: (value?: number) => void;
  }>
) {

  const currentYear = new Date().getFullYear();
  const employmentYearOptions = [];
  const numberOfYear = 100;

  if(props.dateOptions === DateOptions.PastDatesInclusive)
  {
    for (let i = currentYear; i >= currentYear - numberOfYear; i--) {
      employmentYearOptions.push({ value: i, label: i });
    }
  } else if(props.dateOptions === DateOptions.FutureDatesInclusive)
  {
    for (let i = currentYear; i <= currentYear + numberOfYear; i++) {
      employmentYearOptions.push({ value: i, label: i });
    }
  }
  

  return (
    <>
      <select
          value={props.value}
          onChange={(e) => {
            const value = e.target.value ? parseInt(e.target.value) : undefined;
            props.onChange(value);
          }}
        >
          <option value="">{(props.text ? props.text : 'Vælge år')}</option>
          {employmentYearOptions.map((employmentYearOption) => (
            <option
              key={employmentYearOption.value}
              value={employmentYearOption.value}
            >
              {employmentYearOption.label}
            </option>
          ))}
        </select>
    </>
  );
}

export default YearSelect;
