import React, { useState } from 'react';

interface NumericPlusMinusProps {
  onChange: (value: number) => void;
  step: number;
  min: number;
  max: number;
  label: string;
  defaultValue: number;
}

function NumericPlusMinus(
  props: React.PropsWithChildren<NumericPlusMinusProps>
) {
  const [theValue, setTheValue] = useState(props.defaultValue);

  const onAddHandler = () => {
    let newValue = theValue + props.step;
    if (newValue > props.max) {
      newValue = props.max;
    }

    setTheValue(newValue);
    props.onChange(newValue);
  };

  const onMinusHandler = () => {
    let newValue = theValue - props.step;
    if (newValue < props.min) {
      newValue = props.min;
    }

    setTheValue(newValue);
    props.onChange(newValue);
  };

  return (
    <div>
      <h4 className="text-center font-semibold mb-1">{props.label}</h4>
      <div className="flex justify-center items-center">
        <div className="w-16">
          <button onClick={onMinusHandler} className="btn-choice-thin">
            -
          </button>
        </div>
        <div className="w-16 h-8 flex justify-center items-center border mx-2">
          <div>{theValue}</div>
        </div>
        <div className="w-16">
          <button onClick={onAddHandler} className="btn-choice-thin">
            +
          </button>
        </div>
      </div>
    </div>
  );
}

export default NumericPlusMinus;
