import React, { useContext } from 'react';
import { Step } from '../models/Step';
import { ApplicationFormStateContext, } from '../state';
import { thousandNumberSeperator } from '../util';
import { useHistory } from 'react-router-dom';

const ProductIndicator: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const doNotShownIndicator = [Step.Amount, Step.PeriodOfRepayment];
  const history = useHistory();

  const handelAmountClick = () => {
    history.push('/');
  };

  const handelProductClick = () => {
    history.push(`/produkt/${state.amount}`);
  };

  return (
    <>
      { !doNotShownIndicator.includes(state.step) && state.amount && state.periodOfRepaymentOption && (
        <div className="-mx-2 sm:-mx-5 -mt-7 bg-white rounded-t pt-4">
        <div className="w-full">
          <div className="w-1/3 inline-block text-center cursor-pointer" onClick={handelAmountClick} title="Ændre lånebeløb">
            <div className="text-xs uppercase text-regal-darkblue">Lånebeløb</div>
            <div className="text-xs sm:text-xl font-bold text-regal-darkestblue">{thousandNumberSeperator(state.amount)} kr.</div>
          </div>
          <div className="w-1/3 inline-block text-center cursor-pointer" onClick={handelProductClick} title="Ændre afdragsperiode">
            <div className="text-xs uppercase text-regal-darkblue">
              <span className="inline sm:hidden">Periode</span>
              <span className="hidden sm:inline">Afdragsperiode</span>
            </div>
            <div className="text-xs sm:text-xl font-bold text-regal-darkestblue">{state.periodOfRepaymentOption.months} mdr.</div>
          </div>
          <div className="w-1/3 inline-block text-center cursor-pointer" onClick={handelProductClick} title="Ændre ydelse">
            <div className="text-xs uppercase text-regal-darkblue">Betaling<span className="hidden sm:inline"> pr. md</span></div>
            <div className="text-xs sm:text-xl font-bold text-regal-darkestblue">{thousandNumberSeperator(state.periodOfRepaymentOption.monthlyCost)} kr./md.</div>          
          </div>
        </div>
        </div>
      )}
    </>
  );
};

export default ProductIndicator;
