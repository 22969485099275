/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CoCustomer,
    CoCustomerFromJSON,
    CoCustomerFromJSONTyped,
    CoCustomerToJSON,
    Customer,
    CustomerFromJSON,
    CustomerFromJSONTyped,
    CustomerToJSON,
    Merchant,
    MerchantFromJSON,
    MerchantFromJSONTyped,
    MerchantToJSON,
} from './';

/**
 * 
 * @export
 * @interface TaskStatusResponse
 */
export interface TaskStatusResponse {
    /**
     * 
     * @type {Merchant}
     * @memberof TaskStatusResponse
     */
    merchant?: Merchant;
    /**
     * 
     * @type {Customer}
     * @memberof TaskStatusResponse
     */
    customer?: Customer;
    /**
     * 
     * @type {CoCustomer}
     * @memberof TaskStatusResponse
     */
    coCustomer?: CoCustomer;
}

export function TaskStatusResponseFromJSON(json: any): TaskStatusResponse {
    return TaskStatusResponseFromJSONTyped(json, false);
}

export function TaskStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'merchant': !exists(json, 'merchant') ? undefined : MerchantFromJSON(json['merchant']),
        'customer': !exists(json, 'customer') ? undefined : CustomerFromJSON(json['customer']),
        'coCustomer': !exists(json, 'coCustomer') ? undefined : CoCustomerFromJSON(json['coCustomer']),
    };
}

export function TaskStatusResponseToJSON(value?: TaskStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'merchant': MerchantToJSON(value.merchant),
        'customer': CustomerToJSON(value.customer),
        'coCustomer': CoCustomerToJSON(value.coCustomer),
    };
}


