/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CoCustomer
 */
export interface CoCustomer {
    /**
     * 
     * @type {string}
     * @memberof CoCustomer
     */
    customerUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoCustomer
     */
    customerTaskStatus?: string | null;
}

export function CoCustomerFromJSON(json: any): CoCustomer {
    return CoCustomerFromJSONTyped(json, false);
}

export function CoCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerUrl': !exists(json, 'customerUrl') ? undefined : json['customerUrl'],
        'customerTaskStatus': !exists(json, 'customerTaskStatus') ? undefined : json['customerTaskStatus'],
    };
}

export function CoCustomerToJSON(value?: CoCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerUrl': value.customerUrl,
        'customerTaskStatus': value.customerTaskStatus,
    };
}


