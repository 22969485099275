import { scroller } from 'react-scroll';

const scrollToTopOfContent = () => {
    scroller.scrollTo('top-of-content', {
        duration: 600,
        delay: 0,
        smooth: 'easeInOutQuart'
    });
}

export {
    scrollToTopOfContent
};