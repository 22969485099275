import React, { useContext } from 'react';
import { Step } from '../models/Step';
import { ApplicationFormStateContext } from '../state';
import { Dot } from './Dot';
import { Line } from './Line';

const StepIndicator: React.FunctionComponent = () => {
  const [state] = useContext(ApplicationFormStateContext);

  const contactIndicator = [Step.ContactInfo];
  const personalIndicator = [
    Step.Maritalstatus,
    Step.Children,
    Step.HasCoApplicant,
    Step.CoApplicant,
    Step.AccomodationType,
    Step.HabitationYear,
    Step.PersonalInformation,
    Step.CitizenshipNonDanish,
    Step.CitizenshipStep,
  ];
  const economyIndicator = [
    Step.MemberOfAkasse,
    Step.MonthlyAmountAfterExpenses,
    Step.Cards,
  ];
  const applicationIndicator = [Step.ConditionsStep];

  const dot1 = () => {
    return contactIndicator.includes(state.step);
  };

  const dot2 = () => {
    return personalIndicator.includes(state.step);
  };

  const dot3 = () => {
    return economyIndicator.includes(state.step);
  };

  const dot4 = () => {
    return applicationIndicator.includes(state.step);
  };

  return (
    <>
      {(dot1() || dot2() || dot3() || dot4()) && (
        <div className="-mx-2 sm:-mx-5 bg-white p-4 border-b pt-6">
          <div className="flex flex-row">
            <div className="hidden md:flex w-10"></div>
            <div>
              <Dot active={dot1() || dot2() || dot3() || dot4()} />
            </div>
            <div className="flex-1">
              <Line active={dot1() || dot2() || dot3() || dot4()} />
            </div>
            <div>
              <Dot active={dot2() || dot3() || dot4()} />
            </div>
            <div className="flex-1">
              <Line active={dot2() || dot3() || dot4()} />
            </div>
            <div>
              <Dot active={dot3() || dot4()} />
            </div>
            <div className="flex-1">
              <Line active={dot3() || dot4()} />
            </div>
            <div>
              <Dot active={dot4()} />
            </div>
            <div className="hidden md:flex w-10"></div>
          </div>

          <div className="hidden md:flex flex-row mt-1 text-xs">
            <div
              className={`${dot1() ? 'text-regal-darkblue' : 'text-gray-300'}`}
            >
              Kontaktoplysninger
            </div>
            <div className="flex-1"></div>
            <div
              className={`${
                dot2() ? 'text-regal-darkblue' : 'text-gray-300'
              } text-center -ml-6`}
            >
              Mine oplysninger
            </div>
            <div className="flex-1"></div>
            <div
              className={`${
                dot3() ? 'text-regal-darkblue' : 'text-gray-300'
              } text-center ml-2`}
            >
              Økonomi
            </div>
            <div className="flex-1"></div>
            <div
              className={`${
                dot4() ? 'text-regal-darkblue' : 'text-gray-300'
              } "text-right`}
            >
              Godkend ansøgning
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StepIndicator;
