import React, { useContext, useEffect, useState } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory, useLocation } from 'react-router-dom';
import { ApiConfiguration } from '../api-configuration';
import { ApplicationApi } from '../lib-api';
import { logError } from './util';
import { Loading } from './components/Loading';
import { LoadingState } from './hooks/loading';

const ContinueApplicationStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const [applicationState, setApplicationState] = useState(LoadingState.None);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const applicationId = query.get('applicationId');

  useEffect(() => {
    const getApplication = async () => {
      setApplicationState(LoadingState.Loading);

      const api = new ApplicationApi(ApiConfiguration);
      try {
        const application = await api.getNotStartedApplication({
          applicationId: applicationId,
        });

        if (!application) {
          throw 'not found';
        }

        dispatch({ type: 'set_applicationId', values: application.email });
        dispatch({ type: 'set_amount', values: application.amount });
        dispatch({
          type: 'set_periodOfRepaymentOption',
          values: application.periodOfRepaymentOption,
        });
        dispatch({
          type: 'set_contact_information',
          values: {
            name: application.name,
            mobile: application.mobile,
            email: application.email,
          },
        });
        dispatch({
          type: 'set_maritalStatus',
          values: application.maritalStatus,
        });
        dispatch({ type: 'set_children', values: application.children });
        dispatch({
          type: 'set_coApplicant',
          coApplicantInfoGovermentId: undefined,
          coApplicantInfoMobile: application.coApplicantInfoMobile,
          coApplicantInfoEmail: application.coApplicantInfoEmail,
          coApplicantInfoEmploymentType:
            application.coApplicantInfoEmploymentType,
          coApplicantInfoEmploymentYear:
            application.coApplicantInfoEmploymentYear,
        });
        dispatch({
          type: 'set_accomodationtype',
          values: application.accomodationType,
        });
        dispatch({
          type: 'set_habitationyear',
          values: application.habitationYear,
        });
        dispatch({ type: 'set_govermentId', values: undefined });
        dispatch({ type: 'set_citizenship', values: application.citizenship });
        dispatch({
          type: 'set_inDenmarkSinceYear',
          values: application.inDenmarkSinceYear,
        });
        dispatch({
          type: 'set_residentPermit',
          values: application.residentPermit,
        });
        dispatch({
          type: 'set_residentPermitValidDate',
          values: application.residentPermitValidDate,
        });
        dispatch({
          type: 'set_memberOfAkasse',
          values: application.memberOfAkasse,
        });
        dispatch({
          type: 'set_employmentType',
          values: application.employmentType,
        });
        dispatch({
          type: 'set_employmentYear',
          values: application.employmentYear,
        });
        dispatch({
          type: 'set_educationalLevel',
          values: application.educationalLevel,
        });
        dispatch({
          type: 'set_cards',
          creditCardsDinersClub: application.creditCardsDinersClub,
          creditCardsMasterCard: application.creditCardsMasterCard,
          creditCardsPetrol: application.creditCardsPetrol,
          creditCardsVisa: application.creditCardsVisa,
          debitCardsMasterCard: application.debitCardsMasterCard,
          dankort: application.dankort,
        });
        dispatch({
          type: 'set_children_group1',
          values: application.childrenGroup1,
        });
        dispatch({
          type: 'set_children_group2',
          values: application.childrenGroup2,
        });
        dispatch({
          type: 'set_children_group3',
          values: application.childrenGroup3,
        });
        dispatch({
          type: 'set_children_group4',
          values: application.childrenGroup4,
        });
        dispatch({
          type: 'set_share_of_expenses',
          values: application.shareOfExpenses,
        });

        history.push(`/kunde`);
      } catch (e) {
        setErrorMessage('Der opstod en fejl, prøv igen eller kontakt support');
        logError(e);
      } finally {
        setApplicationState(LoadingState.None);
      }
    };

    if (applicationId) {
      getApplication();
      return;
    }
  }, [applicationId]);

  return (
    <>
      <h2>Fortsæt ansøgning</h2>

      {errorMessage && (
        <div className="text-center font-bold text-red-700 m-5">
          {errorMessage}
        </div>
      )}

      {applicationState === LoadingState.Loading && (
        <div className="mt-5">
          <Loading text="Henter ansøgning..." />
        </div>
      )}
    </>
  );
};

export default ContinueApplicationStep;
