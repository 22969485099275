/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationStatus,
    ApplicationStatusFromJSON,
    ApplicationStatusFromJSONTyped,
    ApplicationStatusToJSON,
    TaskStatusResponse,
    TaskStatusResponseFromJSON,
    TaskStatusResponseFromJSONTyped,
    TaskStatusResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetStatusesResponse
 */
export interface GetStatusesResponse {
    /**
     * 
     * @type {TaskStatusResponse}
     * @memberof GetStatusesResponse
     */
    subStatuses?: TaskStatusResponse;
    /**
     * 
     * @type {ApplicationStatus}
     * @memberof GetStatusesResponse
     */
    status?: ApplicationStatus;
}

export function GetStatusesResponseFromJSON(json: any): GetStatusesResponse {
    return GetStatusesResponseFromJSONTyped(json, false);
}

export function GetStatusesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStatusesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subStatuses': !exists(json, 'subStatuses') ? undefined : TaskStatusResponseFromJSON(json['subStatuses']),
        'status': !exists(json, 'status') ? undefined : ApplicationStatusFromJSON(json['status']),
    };
}

export function GetStatusesResponseToJSON(value?: GetStatusesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subStatuses': TaskStatusResponseToJSON(value.subStatuses),
        'status': ApplicationStatusToJSON(value.status),
    };
}


