/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddApplicationNotesRequest,
    AddApplicationNotesRequestFromJSON,
    AddApplicationNotesRequestToJSON,
    ApplicationSearchRequest,
    ApplicationSearchRequestFromJSON,
    ApplicationSearchRequestToJSON,
    ApplicationSearchResponse,
    ApplicationSearchResponseFromJSON,
    ApplicationSearchResponseToJSON,
    GetApplicationsResponse,
    GetApplicationsResponseFromJSON,
    GetApplicationsResponseToJSON,
    GetAverageInspectiontimeResponse,
    GetAverageInspectiontimeResponseFromJSON,
    GetAverageInspectiontimeResponseToJSON,
    GetStatsResponse,
    GetStatsResponseFromJSON,
    GetStatsResponseToJSON,
    LoginRequest,
    LoginRequestFromJSON,
    LoginRequestToJSON,
    LoginResponse,
    LoginResponseFromJSON,
    LoginResponseToJSON,
} from '../models';

export interface AddApplicationNotesOperationRequest {
    addApplicationNotesRequest?: AddApplicationNotesRequest;
}

export interface ApplicationSearchOperationRequest {
    applicationSearchRequest?: ApplicationSearchRequest;
}

export interface GetApplicationsRequest {
    email?: string;
}

export interface GetAverageInspectiontimeRequest {
    from?: string;
    to?: string;
}

export interface GetStatsRequest {
    from?: string;
    to?: string;
}

export interface LoginOperationRequest {
    loginRequest?: LoginRequest;
}

/**
 * 
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     */
    async addApplicationNotesRaw(requestParameters: AddApplicationNotesOperationRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/addapplicationnotes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddApplicationNotesRequestToJSON(requestParameters.addApplicationNotesRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addApplicationNotes(requestParameters: AddApplicationNotesOperationRequest): Promise<void> {
        await this.addApplicationNotesRaw(requestParameters);
    }

    /**
     */
    async applicationSearchRaw(requestParameters: ApplicationSearchOperationRequest): Promise<runtime.ApiResponse<ApplicationSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/applicationsearch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationSearchRequestToJSON(requestParameters.applicationSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async applicationSearch(requestParameters: ApplicationSearchOperationRequest): Promise<ApplicationSearchResponse> {
        const response = await this.applicationSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get ALL applications per email and their current status
     */
    async getApplicationsRaw(requestParameters: GetApplicationsRequest): Promise<runtime.ApiResponse<GetApplicationsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/applications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetApplicationsResponseFromJSON(jsonValue));
    }

    /**
     * Get ALL applications per email and their current status
     */
    async getApplications(requestParameters: GetApplicationsRequest): Promise<GetApplicationsResponse> {
        const response = await this.getApplicationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * CompletedApplicationsAtResurs is the total count of applications that went through inspection and into either rejected or signingrequired
     * Get average application time over a period from inspection to rejected/signingrequired
     */
    async getAverageInspectiontimeRaw(requestParameters: GetAverageInspectiontimeRequest): Promise<runtime.ApiResponse<GetAverageInspectiontimeResponse>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/inspectiontime`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAverageInspectiontimeResponseFromJSON(jsonValue));
    }

    /**
     * CompletedApplicationsAtResurs is the total count of applications that went through inspection and into either rejected or signingrequired
     * Get average application time over a period from inspection to rejected/signingrequired
     */
    async getAverageInspectiontime(requestParameters: GetAverageInspectiontimeRequest): Promise<GetAverageInspectiontimeResponse> {
        const response = await this.getAverageInspectiontimeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get STATS for applications over a given period
     */
    async getStatsRaw(requestParameters: GetStatsRequest): Promise<runtime.ApiResponse<GetStatsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStatsResponseFromJSON(jsonValue));
    }

    /**
     * Get STATS for applications over a given period
     */
    async getStats(requestParameters: GetStatsRequest): Promise<GetStatsResponse> {
        const response = await this.getStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Login to admin
     */
    async loginRaw(requestParameters: LoginOperationRequest): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Login to admin
     */
    async login(requestParameters: LoginOperationRequest): Promise<LoginResponse> {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async statusesRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async statuses(): Promise<Array<string>> {
        const response = await this.statusesRaw();
        return await response.value();
    }

}
