/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InitializeApplicationRequest
 */
export interface InitializeApplicationRequest {
    /**
     * 
     * @type {number}
     * @memberof InitializeApplicationRequest
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof InitializeApplicationRequest
     */
    paymentMethodId: string;
    /**
     * 
     * @type {string}
     * @memberof InitializeApplicationRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InitializeApplicationRequest
     */
    mobile: string;
    /**
     * 
     * @type {string}
     * @memberof InitializeApplicationRequest
     */
    email: string;
}

export function InitializeApplicationRequestFromJSON(json: any): InitializeApplicationRequest {
    return InitializeApplicationRequestFromJSONTyped(json, false);
}

export function InitializeApplicationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitializeApplicationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'paymentMethodId': json['paymentMethodId'],
        'name': json['name'],
        'mobile': json['mobile'],
        'email': json['email'],
    };
}

export function InitializeApplicationRequestToJSON(value?: InitializeApplicationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'paymentMethodId': value.paymentMethodId,
        'name': value.name,
        'mobile': value.mobile,
        'email': value.email,
    };
}


