/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationData,
    ApplicationDataFromJSON,
    ApplicationDataFromJSONTyped,
    ApplicationDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetApplicationsResponse
 */
export interface GetApplicationsResponse {
    /**
     * 
     * @type {Array<ApplicationData>}
     * @memberof GetApplicationsResponse
     */
    applications?: Array<ApplicationData> | null;
}

export function GetApplicationsResponseFromJSON(json: any): GetApplicationsResponse {
    return GetApplicationsResponseFromJSONTyped(json, false);
}

export function GetApplicationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetApplicationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applications': !exists(json, 'applications') ? undefined : (json['applications'] === null ? null : (json['applications'] as Array<any>).map(ApplicationDataFromJSON)),
    };
}

export function GetApplicationsResponseToJSON(value?: GetApplicationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applications': value.applications === undefined ? undefined : (value.applications === null ? null : (value.applications as Array<any>).map(ApplicationDataToJSON)),
    };
}


