import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { subYears } from 'date-fns';
import { Formik } from 'formik';
import { trackEvent } from '../util/gtm';
import YearSelect, { DateOptions } from './components/YearSelect';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';
import { saveStateRemote } from './util';

const HabitationYearStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();
  const { habitationYear } = state;
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  useEffect(() => {
    trackEvent('Flow', 'Load', 'HabitationYearStep');
    dispatch({ type: 'set_step', step: Step.HabitationYear });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => { await saveStateRemote(state); }
    saveState();
  }, []);

  const validationSchema = yup.object().shape({
    habitationYear: yup
      .date()
      .min(subYears(today, 100), 'Vælg indflytning')
      .max(
        today,
        'Vælg indflytning tidligere end i dag'
      )
      .required('Vælg årstal'),
  });

  return (
      <>
      <h2>Tid på adresse</h2>

      <Formik
        initialValues={{
          habitationYear: habitationYear
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch({
            type: 'set_habitationyear',
            values: values.habitationYear,
          });
          history.push('/statsborgerskab');
        }}
      >
        {(formikProps) => (
          <div className="mt-5">
            <div>
              <label className="label-default">Hvilket år flyttede du ind på din nuværende adresse?</label>
              <YearSelect
                    dateOptions={DateOptions.PastDatesInclusive}
                    text="Vælg årstal"
                    value={formikProps.values.habitationYear?.getFullYear()}
                    onChange={(value) => {
                      const date: Date = value
                        ? new Date(Date.UTC(value, 0, 1))
                        : undefined;
                      formikProps.setFieldValue(
                        'habitationYear',
                        date,
                        true
                      );
                    }}
                  />

              {(formikProps.touched.habitationYear ||
                formikProps.submitCount > 0) &&
                formikProps.errors.habitationYear && (
                  <div className="invalid-feedback">
                    {formikProps.errors.habitationYear}
                  </div>
                )}
            </div>
            <div className="container-btn">
              <BackButton />
              
              <button
                className="btn-next"
                type="submit"
                onClick={() => formikProps.submitForm()}
              >
                Næste
              </button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default HabitationYearStep;
