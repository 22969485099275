/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrackingType,
    TrackingTypeFromJSON,
    TrackingTypeFromJSONTyped,
    TrackingTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Tracking
 */
export interface Tracking {
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    source?: string | null;
    /**
     * 
     * @type {TrackingType}
     * @memberof Tracking
     */
    type?: TrackingType;
    /**
     * 
     * @type {boolean}
     * @memberof Tracking
     */
    completed?: boolean;
}

export function TrackingFromJSON(json: any): Tracking {
    return TrackingFromJSONTyped(json, false);
}

export function TrackingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tracking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'type': !exists(json, 'type') ? undefined : TrackingTypeFromJSON(json['type']),
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
    };
}

export function TrackingToJSON(value?: Tracking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'source': value.source,
        'type': TrackingTypeToJSON(value.type),
        'completed': value.completed,
    };
}


