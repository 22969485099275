import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import ButtonSelect from './components/ButtonSelect';
import { trackEvent } from '../util/gtm';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';
import { saveStateRemote } from './util';

const AccomodationTypeStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();
  
  const accomodationTypeOptions = [
    { value: 'OWN_PROPERTY', label: 'Eget hus' },
    { value: 'CONDOMINIUM', label: 'Ejerlejlighed' },
    { value: 'RENTAL', label: 'Lejebolig' },
    { value: 'HOUSING_COOPERATIVE', label: 'Andelsbolig' },
    { value: 'WITH_PARENTS', label: 'Hjemmeboende' },
    { value: 'ROOMER', label: 'Lejet værelse' },
    { value: 'OTHER', label: 'Anden' },
  ];

  useEffect(() => {
    trackEvent("Flow", "Load", "AccomodationTypeStep");
    dispatch({ type: 'set_step', step: Step.AccomodationType });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => { await saveStateRemote(state); }
    saveState();
  }, []);

  const onOptionSelected = (option) => {
    dispatch({type:'set_accomodationtype', values: option.value })
    history.push('/bolig-indflytning')
  };

  return (
    <>
      <h2>Nuværende boligform</h2>

      <div className="mt-5">
        <ButtonSelect
          options={accomodationTypeOptions}
          onOptionSelected={(option) => onOptionSelected(option)}
          option={undefined}
          getOptionText={(option) => {
            return option.label;
          }}
          getOptionKey={(option) => {
            return option.value;
          }}
        ></ButtonSelect>
      </div>

      

      <div className="container-btn">
        <BackButton />
      </div>
    </>
  );
};

export default AccomodationTypeStep;
