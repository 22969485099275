/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusCount,
    StatusCountFromJSON,
    StatusCountFromJSONTyped,
    StatusCountToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetStatsResponse
 */
export interface GetStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetStatsResponse
     */
    totalApplicationCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetStatsResponse
     */
    totalApplicationCountSendToResurs?: number;
    /**
     * 
     * @type {number}
     * @memberof GetStatsResponse
     */
    totalApplicationUniqueCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetStatsResponse
     */
    totalApplicationUniqueCountSendToResurs?: number;
    /**
     * 
     * @type {Array<StatusCount>}
     * @memberof GetStatsResponse
     */
    statusGrouped?: Array<StatusCount> | null;
}

export function GetStatsResponseFromJSON(json: any): GetStatsResponse {
    return GetStatsResponseFromJSONTyped(json, false);
}

export function GetStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalApplicationCount': !exists(json, 'totalApplicationCount') ? undefined : json['totalApplicationCount'],
        'totalApplicationCountSendToResurs': !exists(json, 'totalApplicationCountSendToResurs') ? undefined : json['totalApplicationCountSendToResurs'],
        'totalApplicationUniqueCount': !exists(json, 'totalApplicationUniqueCount') ? undefined : json['totalApplicationUniqueCount'],
        'totalApplicationUniqueCountSendToResurs': !exists(json, 'totalApplicationUniqueCountSendToResurs') ? undefined : json['totalApplicationUniqueCountSendToResurs'],
        'statusGrouped': !exists(json, 'statusGrouped') ? undefined : (json['statusGrouped'] === null ? null : (json['statusGrouped'] as Array<any>).map(StatusCountFromJSON)),
    };
}

export function GetStatsResponseToJSON(value?: GetStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalApplicationCount': value.totalApplicationCount,
        'totalApplicationCountSendToResurs': value.totalApplicationCountSendToResurs,
        'totalApplicationUniqueCount': value.totalApplicationUniqueCount,
        'totalApplicationUniqueCountSendToResurs': value.totalApplicationUniqueCountSendToResurs,
        'statusGrouped': value.statusGrouped === undefined ? undefined : (value.statusGrouped === null ? null : (value.statusGrouped as Array<any>).map(StatusCountToJSON)),
    };
}


