import React, { useContext, useEffect, useState } from "react";
import { ApplicationFormStateContext } from "./state";
import { useLocation, Link } from "react-router-dom";
import { useStatus } from "./hooks/status";
import { useHistory } from "react-router-dom";
import { StatusEnum } from "../lib-api/models/StatusEnum";
import { LoadingState } from "./hooks/loading";
import { Loading } from "./components/Loading";
import { trackEvent } from "../util/gtm";
import { scrollToTopOfContent } from "./features/scroll";
import { Step } from "./models/Step";

const StatusStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const [showEskatButton, setShowEskatButton] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const applicationId = query.get("applicationId");

  const { status, statusState } = useStatus(applicationId);

  useEffect(() => {
    trackEvent("Flow", "Load", "StatusStep");
    dispatch({ type: "set_step", step: Step.Status });
    dispatch({ type: "reset_applicationData" });
    scrollToTopOfContent();
  }, []);

  useEffect(() => {
    if (
      statusState === LoadingState.Success &&
      status?.status === StatusEnum.Eskatrequired
    ) {
      window.location.href = status.continueUrl;
      setTimeout(() => {
        setShowEskatButton(true);
      }, 2000);
    }
  }, [status, statusState]);

  return (
    <>
      <h2>Status på låneansøgning</h2>

      {statusState === LoadingState.Loading && (
        <div className="mt-4">
          <Loading text="Henter status...." />
        </div>
      )}

      {(statusState === LoadingState.Error ||
        statusState === LoadingState.BadRequest) && (
        <div className="text-center font-bold text-red-700 m-5">
          Der opstod en fejl. Kontakt support på{" "}
          <a className="font-semibold" href="tel:30140256">
            30 14 02 56
          </a>{" "}
          eller prøv igen.
          {/* {applicationId && (
            <><br />ID ved henvendelse: <span className="font-bold">{applicationId}</span></>
          )}    */}
        </div>
      )}

      {(statusState === LoadingState.Success &&
        status?.status === StatusEnum.Approved && (
          <>
            <h3 className="mt-3">Godkendt</h3>
            <p className="text-center mt-2">
              Resurs Bank A/S har godkendt din kreditansøgning. Du vil modtage
              en bekræftelsesmail med information om lån, betingelser for brug
              m.m., samt hvordan du bruger pengene hos den forhandler eller
              person, som du handler bil med.
            </p>
            <p className="text-center mt-2">
              Har du ikke besluttet hvilken bil det skal være? Se mere end
              40.000 biler til salg hos forhandlere i Danmark.
            </p>
            <p className="text-center mt-6">
              <Link to={{ pathname: "https://bilhandel.dk" }} target="_top">
                <button type="button" className="btn-next">
                  Vis mig biler
                </button>
              </Link>
            </p>
            <p className="text-center mt-6">
              <span className="font-semibold">Gode råd er... Gratis</span>
              <br />
              Har du spørgsmål til din kredit hos Resurs Bank, eller vil du blot
              have et godt råd om, hvad du skal være opmærksom på, når du
              handler bil? Vi har mange års erfaring fra bilbranchen og tilbyder
              dig gratis rådgivning.
            </p>
          </>
        )) ||
        (status?.status === StatusEnum.Rejected && (
          <>
            <h3 className="mt-3">Desværre...</h3>
            <p className="text-center mt-2">
              Resurs Bank har afvist din kreditansøgning. Du kan prøve ansøge om
              et lavere beløb, eller søge igen på et senere tidspunkt.
            </p>
            <p className="text-center mt-6">
              <Link to="/">
                <button type="button" className="btn-next">
                  Ansøg igen
                </button>
              </Link>
            </p>
            <p className="text-center mt-10">
              Du kan også finde attraktive lånemuligheder gennem vores partner
              via knappen herunder.
            </p>
            <p className="text-center mt-6">
              <Link
                to={{ pathname: "https://bilhandel.dk/billaan" }}
                target="_top"
              >
                <button type="button" className="btn-next">
                  Andre lån
                </button>
              </Link>
            </p>
          </>
        )) ||
        (status?.status === StatusEnum.Eskatrequired && (
          <>
            <h3 className="mt-3">Omstiller til eSKAT</h3>
            {showEskatButton && (
              <p className="text-center mt-6">
                <Link to={{ pathname: status.continueUrl }} target="_top">
                  <button type="button" className="btn-next">
                    Klik her
                  </button>
                </Link>
              </p>
            )}
          </>
        )) ||
        (status?.status === StatusEnum.Signingrequired && (
          <>
            <h3 className="mt-3">Underskriv aftale</h3>
            <p className="text-center mt-2">
              Tillykke! Du er blevet godkendt og mangler nu blot at underskrive
              aftalen.
            </p>
            <p className="text-center mt-6">
              <Link to={{ pathname: status.continueUrl }} target="_top">
                <button type="button" className="btn-next">
                  Underskriv her
                </button>
              </Link>
            </p>
          </>
        )) ||
        (status?.status === StatusEnum.Inspection && (
          <>
            <h3 className="mt-3">En medarbejder skal gennemse din ansøgning</h3>
            <p className="text-center mt-2">
              Din ansøgning kræver opmærksomhed fra en medarbejder hos Resurs
              Bank. Du vil få besked pr. sms og e-mail indenfor 15 min, hvis du
              har ansøgt indenfor supportens åbningstid 08.00-17.00.
            </p>
            <p className="text-center mt-2">
              Du er også velkommen til at ringe til Resurs Bank på telefon:{" "}
              <a className="font-semibold" href="tel:39131680">
                39 13 16 80
              </a>{" "}
              eller skrive til <span className="italic">Loan Team</span> på{" "}
              <a className="font-semibold" href="mailto:lt@resurs.dk">
                lt@resurs.dk
              </a>
              .
            </p>
          </>
        )) ||
        (status?.status === StatusEnum.Supplementingrequired && (
          <>
            <h3 className="mt-3">Ring til os - vi har brug for at vide mere</h3>
            <p className="text-center mt-2">
              Tak for din kreditansøgning hos Resurs Bank A/S. Vi har brug for
              flere oplysninger, og derfor beder vi dig ringe til os. Oftest kan
              det klares på få minutter, hvor du bekræfter dine oplysninger pr.
              telefon, og sommetider bedes du fremsende nogle dokumenter, såsom
              en lønseddel.
            </p>
            <p className="text-center mt-2">
              Kontakt supporten alle ugens dage mellem 08.00-17.00 på{" "}
              <a className="font-semibold" href="tel:39131680">
                39 13 16 80
              </a>{" "}
              eller <span className="italic">Loan Team</span> på{" "}
              <a className="font-semibold" href="mailto:lt@resurs.dk">
                lt@resurs.dk
              </a>
              .
            </p>
          </>
        ))}
    </>
  );
};

export default StatusStep;
