/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PeriodOfRepaymentOption
 */
export interface PeriodOfRepaymentOption {
    /**
     * 
     * @type {string}
     * @memberof PeriodOfRepaymentOption
     */
    paymentMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodOfRepaymentOption
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PeriodOfRepaymentOption
     */
    interest?: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodOfRepaymentOption
     */
    months?: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodOfRepaymentOption
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodOfRepaymentOption
     */
    monthlyCost?: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodOfRepaymentOption
     */
    administrationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodOfRepaymentOption
     */
    agreementFee?: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodOfRepaymentOption
     */
    effectiveInterest?: number;
}

export function PeriodOfRepaymentOptionFromJSON(json: any): PeriodOfRepaymentOption {
    return PeriodOfRepaymentOptionFromJSONTyped(json, false);
}

export function PeriodOfRepaymentOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeriodOfRepaymentOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentMethodId': !exists(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'interest': !exists(json, 'interest') ? undefined : json['interest'],
        'months': !exists(json, 'months') ? undefined : json['months'],
        'totalCost': !exists(json, 'totalCost') ? undefined : json['totalCost'],
        'monthlyCost': !exists(json, 'monthlyCost') ? undefined : json['monthlyCost'],
        'administrationFee': !exists(json, 'administrationFee') ? undefined : json['administrationFee'],
        'agreementFee': !exists(json, 'agreementFee') ? undefined : json['agreementFee'],
        'effectiveInterest': !exists(json, 'effectiveInterest') ? undefined : json['effectiveInterest'],
    };
}

export function PeriodOfRepaymentOptionToJSON(value?: PeriodOfRepaymentOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentMethodId': value.paymentMethodId,
        'description': value.description,
        'interest': value.interest,
        'months': value.months,
        'totalCost': value.totalCost,
        'monthlyCost': value.monthlyCost,
        'administrationFee': value.administrationFee,
        'agreementFee': value.agreementFee,
        'effectiveInterest': value.effectiveInterest,
    };
}


