const Dot: React.FC<{active:boolean}> = (props) => {

  return (
    
        <div 
          className={`rounded-full flex items-center justify-center ${props.active ? 'bg-regal-darkblue' : 'bg-gray-300'}`} 
          style={{height:"19px", width:"19px"}}>
        </div>
  );
};


export { Dot };
