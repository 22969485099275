import React from "react";

const Footer: React.FC<{}> = (props) => {
  return (
    <div className="bg-gray-800 px-5 sm:px-10 py-6 text-gray-300">
      <div className="flex flex-col sm:flex-row max-w-6xl mx-auto">
        <div className="flex-1 leading-7">
          <span className="inline-block">
            <img
              src="/images/bilhandel_light.svg"
              className="h-6"
              alt="bilhandel billån"
            />
          </span>
          <br />
          Finansieringen udbydes i samarbejde med:
          <br />
          Resurs Bank A/S
          <br />
          Delta Park 46
          <br />
          DK-2665 Vallensbæk Strand
          <br />
          <a className="underline text-regal-blue" href="mailto:mail@resurs.dk">
            mail@resurs.dk
          </a>
        </div>

        <div className="flex-1 leading-7 mt-5 sm:mt-0 sm:px-5">
          <a className="underline text-regal-blue" href="/privatlivspolitik">
            Privatlivspolitik
          </a>
          <br />
          <a
            className="underline text-regal-blue"
            href="https://documenthandler.resurs.com/Dokument.pdf?land=DK&language=da&customerType=natural&docType=commonTerms"
            target="_blank"
            rel="noreferrer"
          >
            Resurs kontovilkår
          </a>
        </div>

        <div className="flex-1 mt-5 sm:mt-0">
          <div className="text-sm font-semibold">Brug for hjælp?</div>
          <div className="text-xl mt-5 font-bold">
            <a className="text-regal-blue" href="tel:+4530140256">
              +45 30 14 02 56
            </a>
          </div>
          <div className="text-sm font-semibold mt-6">Sikker ansøgning med</div>
          <div className="mt-2 opacity-80">
            {/* <img src="images/nemid.png" /> */}
            <img src="images/NEMID_RGB_WHITE.svg" alt="nemid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
