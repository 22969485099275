/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StartApplicationRequest
 */
export interface StartApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    maritalStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    children?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    coApplicantInfoGovermentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    coApplicantInfoMobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    coApplicantInfoEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    coApplicantInfoEmploymentType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StartApplicationRequest
     */
    coApplicantInfoEmploymentYear?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    accomodationType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StartApplicationRequest
     */
    habitationYear?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    govermentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    citizenship?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    ancestralHomeland?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StartApplicationRequest
     */
    inDenmarkSinceYear?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    residentPermit?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StartApplicationRequest
     */
    residentPermitValidDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof StartApplicationRequest
     */
    memberOfAkasse?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    employmentType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StartApplicationRequest
     */
    employmentYear?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    educationalLevel?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StartApplicationRequest
     */
    dankort?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StartApplicationRequest
     */
    debitCardsMasterCard?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StartApplicationRequest
     */
    creditCardsMasterCard?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StartApplicationRequest
     */
    creditCardsVisa?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StartApplicationRequest
     */
    creditCardsDinersClub?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StartApplicationRequest
     */
    creditCardsPetrol?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof StartApplicationRequest
     */
    childrenGroup1?: number;
    /**
     * 
     * @type {number}
     * @memberof StartApplicationRequest
     */
    childrenGroup2?: number;
    /**
     * 
     * @type {number}
     * @memberof StartApplicationRequest
     */
    childrenGroup3?: number;
    /**
     * 
     * @type {number}
     * @memberof StartApplicationRequest
     */
    childrenGroup4?: number;
    /**
     * 
     * @type {number}
     * @memberof StartApplicationRequest
     */
    shareOfExpenses?: number;
    /**
     * 
     * @type {string}
     * @memberof StartApplicationRequest
     */
    applicationId?: string;
}

export function StartApplicationRequestFromJSON(json: any): StartApplicationRequest {
    return StartApplicationRequestFromJSONTyped(json, false);
}

export function StartApplicationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartApplicationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maritalStatus': !exists(json, 'maritalStatus') ? undefined : json['maritalStatus'],
        'children': !exists(json, 'children') ? undefined : json['children'],
        'coApplicantInfoGovermentId': !exists(json, 'coApplicantInfoGovermentId') ? undefined : json['coApplicantInfoGovermentId'],
        'coApplicantInfoMobile': !exists(json, 'coApplicantInfoMobile') ? undefined : json['coApplicantInfoMobile'],
        'coApplicantInfoEmail': !exists(json, 'coApplicantInfoEmail') ? undefined : json['coApplicantInfoEmail'],
        'coApplicantInfoEmploymentType': !exists(json, 'coApplicantInfoEmploymentType') ? undefined : json['coApplicantInfoEmploymentType'],
        'coApplicantInfoEmploymentYear': !exists(json, 'coApplicantInfoEmploymentYear') ? undefined : (json['coApplicantInfoEmploymentYear'] === null ? null : new Date(json['coApplicantInfoEmploymentYear'])),
        'accomodationType': !exists(json, 'accomodationType') ? undefined : json['accomodationType'],
        'habitationYear': !exists(json, 'habitationYear') ? undefined : (json['habitationYear'] === null ? null : new Date(json['habitationYear'])),
        'govermentId': !exists(json, 'govermentId') ? undefined : json['govermentId'],
        'citizenship': !exists(json, 'citizenship') ? undefined : json['citizenship'],
        'ancestralHomeland': !exists(json, 'ancestralHomeland') ? undefined : json['ancestralHomeland'],
        'inDenmarkSinceYear': !exists(json, 'inDenmarkSinceYear') ? undefined : (json['inDenmarkSinceYear'] === null ? null : new Date(json['inDenmarkSinceYear'])),
        'residentPermit': !exists(json, 'residentPermit') ? undefined : json['residentPermit'],
        'residentPermitValidDate': !exists(json, 'residentPermitValidDate') ? undefined : (json['residentPermitValidDate'] === null ? null : new Date(json['residentPermitValidDate'])),
        'memberOfAkasse': !exists(json, 'memberOfAkasse') ? undefined : json['memberOfAkasse'],
        'employmentType': !exists(json, 'employmentType') ? undefined : json['employmentType'],
        'employmentYear': !exists(json, 'employmentYear') ? undefined : (json['employmentYear'] === null ? null : new Date(json['employmentYear'])),
        'educationalLevel': !exists(json, 'educationalLevel') ? undefined : json['educationalLevel'],
        'dankort': !exists(json, 'dankort') ? undefined : json['dankort'],
        'debitCardsMasterCard': !exists(json, 'debitCardsMasterCard') ? undefined : json['debitCardsMasterCard'],
        'creditCardsMasterCard': !exists(json, 'creditCardsMasterCard') ? undefined : json['creditCardsMasterCard'],
        'creditCardsVisa': !exists(json, 'creditCardsVisa') ? undefined : json['creditCardsVisa'],
        'creditCardsDinersClub': !exists(json, 'creditCardsDinersClub') ? undefined : json['creditCardsDinersClub'],
        'creditCardsPetrol': !exists(json, 'creditCardsPetrol') ? undefined : json['creditCardsPetrol'],
        'childrenGroup1': !exists(json, 'childrenGroup1') ? undefined : json['childrenGroup1'],
        'childrenGroup2': !exists(json, 'childrenGroup2') ? undefined : json['childrenGroup2'],
        'childrenGroup3': !exists(json, 'childrenGroup3') ? undefined : json['childrenGroup3'],
        'childrenGroup4': !exists(json, 'childrenGroup4') ? undefined : json['childrenGroup4'],
        'shareOfExpenses': !exists(json, 'shareOfExpenses') ? undefined : json['shareOfExpenses'],
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
    };
}

export function StartApplicationRequestToJSON(value?: StartApplicationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maritalStatus': value.maritalStatus,
        'children': value.children,
        'coApplicantInfoGovermentId': value.coApplicantInfoGovermentId,
        'coApplicantInfoMobile': value.coApplicantInfoMobile,
        'coApplicantInfoEmail': value.coApplicantInfoEmail,
        'coApplicantInfoEmploymentType': value.coApplicantInfoEmploymentType,
        'coApplicantInfoEmploymentYear': value.coApplicantInfoEmploymentYear === undefined ? undefined : (value.coApplicantInfoEmploymentYear === null ? null : value.coApplicantInfoEmploymentYear.toISOString()),
        'accomodationType': value.accomodationType,
        'habitationYear': value.habitationYear === undefined ? undefined : (value.habitationYear === null ? null : value.habitationYear.toISOString()),
        'govermentId': value.govermentId,
        'citizenship': value.citizenship,
        'ancestralHomeland': value.ancestralHomeland,
        'inDenmarkSinceYear': value.inDenmarkSinceYear === undefined ? undefined : (value.inDenmarkSinceYear === null ? null : value.inDenmarkSinceYear.toISOString()),
        'residentPermit': value.residentPermit,
        'residentPermitValidDate': value.residentPermitValidDate === undefined ? undefined : (value.residentPermitValidDate === null ? null : value.residentPermitValidDate.toISOString()),
        'memberOfAkasse': value.memberOfAkasse,
        'employmentType': value.employmentType,
        'employmentYear': value.employmentYear === undefined ? undefined : (value.employmentYear === null ? null : value.employmentYear.toISOString()),
        'educationalLevel': value.educationalLevel,
        'dankort': value.dankort,
        'debitCardsMasterCard': value.debitCardsMasterCard,
        'creditCardsMasterCard': value.creditCardsMasterCard,
        'creditCardsVisa': value.creditCardsVisa,
        'creditCardsDinersClub': value.creditCardsDinersClub,
        'creditCardsPetrol': value.creditCardsPetrol,
        'childrenGroup1': value.childrenGroup1,
        'childrenGroup2': value.childrenGroup2,
        'childrenGroup3': value.childrenGroup3,
        'childrenGroup4': value.childrenGroup4,
        'shareOfExpenses': value.shareOfExpenses,
        'applicationId': value.applicationId,
    };
}


