import { ApplicationApi, PeriodOfRepaymentOption } from '../../lib-api';
import { useState, useEffect } from 'react';
import { ApiConfiguration } from '../../api-configuration';
import { LoadingState } from './loading';

export function usePeriodOfRepaymentOptions(amount: number) {
  const [periodOfRepaymentOptions, setPeriodOfRepaymentOptions] = useState<PeriodOfRepaymentOption[]>([]);
  const [state, setState] = useState<LoadingState>(LoadingState.None);

  const load = async () => {
    try {
      const api = new ApplicationApi(ApiConfiguration);
      setState(LoadingState.Loading);
      const resp = await api.getPeriodOfRepaymentOptions({ amount });
      setPeriodOfRepaymentOptions(resp);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.Error);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return {
    periodOfRepaymentOptions: periodOfRepaymentOptions,
    periodOfRepaymentOptionsState: state,
  };
}
