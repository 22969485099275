import React from 'react';

interface YesNoProps {
  yesText?: string;
  noText?: string;
  onYes: () => void;
  onNo: () => void;
}

function YesNo(props: React.PropsWithChildren<YesNoProps>) {
  return (
    <div>
      <div className="w-1/2 inline-block p-2">
        <button className="btn-choice" onClick={() => props.onYes()}>{props.yesText ?? 'Ja'}</button>
      </div>
      <div className="w-1/2 inline-block p-2">
        <button className="btn-choice" onClick={() => props.onNo()}>{props.noText ?? 'Nej'}</button>
      </div>
    </div>
  );
}

export default YesNo;
