/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetApplicationStatusResponse,
    GetApplicationStatusResponseFromJSON,
    GetApplicationStatusResponseToJSON,
    GetStatusRequest,
    GetStatusRequestFromJSON,
    GetStatusRequestToJSON,
    GetStatusesRequest,
    GetStatusesRequestFromJSON,
    GetStatusesRequestToJSON,
    GetStatusesResponse,
    GetStatusesResponseFromJSON,
    GetStatusesResponseToJSON,
    InitializeApplicationRequest,
    InitializeApplicationRequestFromJSON,
    InitializeApplicationRequestToJSON,
    InitializeApplicationResponse,
    InitializeApplicationResponseFromJSON,
    InitializeApplicationResponseToJSON,
    NotStartedApplicationResponse,
    NotStartedApplicationResponseFromJSON,
    NotStartedApplicationResponseToJSON,
    PeriodOfRepaymentOption,
    PeriodOfRepaymentOptionFromJSON,
    PeriodOfRepaymentOptionToJSON,
    SetFailedRequest,
    SetFailedRequestFromJSON,
    SetFailedRequestToJSON,
    StartApplicationRequest,
    StartApplicationRequestFromJSON,
    StartApplicationRequestToJSON,
    StoreApplicationRequest,
    StoreApplicationRequestFromJSON,
    StoreApplicationRequestToJSON,
} from '../models';

export interface GetApplicationStatusRequest {
    getStatusRequest?: GetStatusRequest;
}

export interface GetNotStartedApplicationRequest {
    applicationId?: string;
}

export interface GetPeriodOfRepaymentOptionsRequest {
    amount?: number;
}

export interface GetStatusesOperationRequest {
    getStatusesRequest?: GetStatusesRequest;
}

export interface InitializeApplicationOperationRequest {
    initializeApplicationRequest?: InitializeApplicationRequest;
}

export interface SetFailedOperationRequest {
    setFailedRequest?: SetFailedRequest;
}

export interface StartApplicationOperationRequest {
    startApplicationRequest?: StartApplicationRequest;
}

export interface StoreApplicationOperationRequest {
    storeApplicationRequest?: StoreApplicationRequest;
}

/**
 * 
 */
export class ApplicationApi extends runtime.BaseAPI {

    /**
     */
    async getAmountsRaw(): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/application/getamounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getAmounts(): Promise<Array<number>> {
        const response = await this.getAmountsRaw();
        return await response.value();
    }

    /**
     */
    async getApplicationStatusRaw(requestParameters: GetApplicationStatusRequest): Promise<runtime.ApiResponse<GetApplicationStatusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/application/getapplicationstatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetStatusRequestToJSON(requestParameters.getStatusRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetApplicationStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async getApplicationStatus(requestParameters: GetApplicationStatusRequest): Promise<GetApplicationStatusResponse> {
        const response = await this.getApplicationStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNotStartedApplicationRaw(requestParameters: GetNotStartedApplicationRequest): Promise<runtime.ApiResponse<NotStartedApplicationResponse>> {
        const queryParameters: any = {};

        if (requestParameters.applicationId !== undefined) {
            queryParameters['applicationId'] = requestParameters.applicationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/application/getnotstartedapplication`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotStartedApplicationResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNotStartedApplication(requestParameters: GetNotStartedApplicationRequest): Promise<NotStartedApplicationResponse> {
        const response = await this.getNotStartedApplicationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPeriodOfRepaymentOptionsRaw(requestParameters: GetPeriodOfRepaymentOptionsRequest): Promise<runtime.ApiResponse<Array<PeriodOfRepaymentOption>>> {
        const queryParameters: any = {};

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/application/getperiodofrepaymentoptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PeriodOfRepaymentOptionFromJSON));
    }

    /**
     */
    async getPeriodOfRepaymentOptions(requestParameters: GetPeriodOfRepaymentOptionsRequest): Promise<Array<PeriodOfRepaymentOption>> {
        const response = await this.getPeriodOfRepaymentOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getStatusesRaw(requestParameters: GetStatusesOperationRequest): Promise<runtime.ApiResponse<GetStatusesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/application/getstatuses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetStatusesRequestToJSON(requestParameters.getStatusesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStatusesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getStatuses(requestParameters: GetStatusesOperationRequest): Promise<GetStatusesResponse> {
        const response = await this.getStatusesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async initializeApplicationRaw(requestParameters: InitializeApplicationOperationRequest): Promise<runtime.ApiResponse<InitializeApplicationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/application/initializeapplication`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InitializeApplicationRequestToJSON(requestParameters.initializeApplicationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InitializeApplicationResponseFromJSON(jsonValue));
    }

    /**
     */
    async initializeApplication(requestParameters: InitializeApplicationOperationRequest): Promise<InitializeApplicationResponse> {
        const response = await this.initializeApplicationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async setFailedRaw(requestParameters: SetFailedOperationRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/application/setfailed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetFailedRequestToJSON(requestParameters.setFailedRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setFailed(requestParameters: SetFailedOperationRequest): Promise<void> {
        await this.setFailedRaw(requestParameters);
    }

    /**
     */
    async startApplicationRaw(requestParameters: StartApplicationOperationRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/application/startapplication`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartApplicationRequestToJSON(requestParameters.startApplicationRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async startApplication(requestParameters: StartApplicationOperationRequest): Promise<void> {
        await this.startApplicationRaw(requestParameters);
    }

    /**
     */
    async storeApplicationRaw(requestParameters: StoreApplicationOperationRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-ApiKey"] = this.configuration.apiKey("X-ApiKey"); // ApiKey authentication
        }

        const response = await this.request({
            path: `/api/application/storeapplication`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StoreApplicationRequestToJSON(requestParameters.storeApplicationRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async storeApplication(requestParameters: StoreApplicationOperationRequest): Promise<void> {
        await this.storeApplicationRaw(requestParameters);
    }

}
