import React from 'react';

interface ButtonSelectProps<TOption> {
  options: Array<TOption>;
  option: TOption | undefined;
  getOptionText: (option: TOption) => string;
  getOptionKey: (option: TOption) => string;
  onOptionSelected: (option: TOption) => void;
}

function ButtonSelect<TOption>(
  props: React.PropsWithChildren<ButtonSelectProps<TOption>>
) {
  return (
    <div>
      {props.options.map((option) => (
        <div 
          key={props.getOptionKey(option)}
          className="w-1/2 md:w-1/3 p-2 inline-block">
        <button
          className="btn-choice"
          
          onClick={() => props.onOptionSelected(option)}
        >
          {props.getOptionText(option)}
        </button>
        </div>
      ))}
    </div>
  );
}

export default ButtonSelect;
