import { ApplicationApi } from '../../lib-api';
import { useState, useEffect } from 'react';
import { ApiConfiguration } from '../../api-configuration';
import { LoadingState } from './loading';

export function useAmounts() {
  const [amounts, setAmounts] = useState<number[]>([]);
  const [state, setState] = useState<LoadingState>(LoadingState.None);

  const load = async () => {
    try {
      const api = new ApplicationApi(ApiConfiguration);
      setState(LoadingState.Loading);
      const resp = await api.getAmounts();
      setAmounts(resp);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.Error);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return {
    amounts: amounts,
    amountsState: state,
  };
}
