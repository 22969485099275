import React, { useContext, useEffect } from 'react';
import { useAmounts } from './hooks/amounts';
import { ApplicationFormStateContext } from './state';
import { useHistory, useParams } from 'react-router-dom';
import { adServiceTrackingKey, dealerTrackingKey, removeCookie, setCookie, thousandNumberSeperator } from "./util";
import { LoadingState } from './hooks/loading';
import { Loading } from './components/Loading';
import { trackEvent } from "../util/gtm";
import { animateScroll } from 'react-scroll'
import { Step } from './models/Step';

const AmountStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const { amounts, amountsState } = useAmounts();
  const history = useHistory();
  const {partner} = useParams<{partner?: string}>();
  
  /**Dealertracking cookie start */
  if (partner != undefined) {
    removeCookie(adServiceTrackingKey);
    setCookie(dealerTrackingKey, partner);
  }
  /**Dealertracking cookie end */

  /**Adtracking cookie start */
  const adTracking = new URLSearchParams(window.location.search).get(
    adServiceTrackingKey
  );
  if (adTracking) {
    removeCookie(dealerTrackingKey);
    setCookie(adServiceTrackingKey, adTracking);
  }
  /**Adtracking cookie end */

  useEffect(() => {
    trackEvent("Flow", "Load", "AmountStep");
    dispatch({ type: 'set_step', step: Step.Amount });
    animateScroll.scrollToTop();
  }, []);

  useEffect(() => {}, [amounts]);

  const handleClick = (amount: number) => {
    dispatch({ type: 'set_amount', values: amount });
    history.push(`/produkt/${amount}`);
  };
  
  const handleClick_Other = () => {
    history.push('/andet-beloeb');
  };

  return (
    <>
      <h2>Vælg beløb - hvor meget vil du låne til bilen?</h2>
      <h3>Købsbeviset fungerer ligesom en kassekredit - du låner kun de penge, som bilen rent faktisk koster. Vælg et beløb, eller klik på ‘andet’, for at vælge mere nøjagtigt.</h3>

      {amountsState === LoadingState.Loading && (
        <div className="mt-5">
          <Loading text="Henter lånebeløb..." />
        </div>
      )}

      {amountsState === LoadingState.Success && 
      <div className="mt-5">
        {amounts.map((amount, index) => (
          <div 
            className="w-1/2 md:w-1/3 inline-block p-2"
            key={amount + index}>
            <button
              className="btn-choice"
              onClick={() => handleClick(amount)}
            >
              {thousandNumberSeperator(amount)} kr.
            </button>
          </div>
        ))}
        <div className="w-1/2 md:w-1/3 inline-block p-2">
          <button onClick={handleClick_Other} className="btn-choice w-full h-16">Andet...</button>
        </div>
      </div>
      }
    </>
  );
};

export default AmountStep;
