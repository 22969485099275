import React from 'react';

const Loading: React.FC<{
  text?: string;
}> = (props) => {
  return (
    <>
      <div className="text-center">
        {props.text && <div className="text-center">{props.text}</div>}
        <img className="inline-block" src="/images/spinner-1s-v2-200px.gif" alt="indlæser" />
      </div>
    </>
  );
};

export { Loading };
