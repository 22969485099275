import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import { trackEvent } from '../util/gtm';
import YearSelect, { DateOptions } from './components/YearSelect';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';
import { saveStateRemote } from './util';

const MonthlyAmountAfterExpensesStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();
  const {
    monthlyAmountAfterExpenses,
    employmentType,
    employmentYear,
    educationalLevel,
  } = state;

  enum EmploymentTypeSubType {
    Job,
    Education,
    Other,
  }

  const employmentTypeOptions = [
    {
      value: 'WHITE_COLLAR_WORKER',
      label: 'Funktionær/Lønmodtager',
      subtype: EmploymentTypeSubType.Job,
    },
    {
      value: 'PAID_BY_HOUR',
      label: 'Timelønnet',
      subtype: EmploymentTypeSubType.Job,
    },
    {
      value: 'PRE_PENSIONER',
      label: 'Førtidspensionist',
      subtype: EmploymentTypeSubType.Other,
    },
    {
      value: 'PENSIONAR',
      label: 'Pensionist',
      subtype: EmploymentTypeSubType.Other,
    },
    {
      value: 'STAYING_AT_HOME',
      label: 'Hjemmegående',
      subtype: EmploymentTypeSubType.Other,
    },
    {
      value: 'STUDENT',
      label: 'Studerende',
      subtype: EmploymentTypeSubType.Education,
    },
    {
      value: 'TRAINEE',
      label: 'Elev/Lærling',
      subtype: EmploymentTypeSubType.Education,
    },
    { value: 'MANAGER', label: 'Direktør', subtype: EmploymentTypeSubType.Job },
    {
      value: 'EGEN_FORETAGARE',
      label: 'Selvstændig',
      subtype: EmploymentTypeSubType.Job,
    },
    {
      value: 'VIKARIAT',
      label: 'Midlertidigt job',
      subtype: EmploymentTypeSubType.Job,
    },
    {
      value: 'ARBETSLOS',
      label: 'Ledig',
      subtype: EmploymentTypeSubType.Other,
    },
    {
      value: 'OTHER',
      label: 'Anden ansættelse',
      subtype: EmploymentTypeSubType.Job,
    },
  ];

  const isJob = (value: string) => {
    return (
      employmentTypeOptions.find((opt) => opt.value === value)?.subtype ===
      EmploymentTypeSubType.Job
    );
  };

  const isEducation = (value: string) => {
    return (
      employmentTypeOptions.find((opt) => opt.value === value)?.subtype ===
      EmploymentTypeSubType.Education
    );
  };

  useEffect(() => {
    trackEvent('Flow', 'Load', 'MonthlyAmountAfterExpensesStep');
    dispatch({ type: 'set_step', step: Step.MonthlyAmountAfterExpenses });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => {
      await saveStateRemote(state);
    };
    saveState();
  }, []);

  const schema = yup.object({
    employmentType: yup.string().required('Vælg ansættelsesforhold'),
    employmentYear: yup.string().when('employmentType', {
      is: (value) => value && isJob(value),
      then: yup.string().required('Vælg hvornår blev du ansat i nuværende job'),
      otherwise: yup.string(),
    }),
  });

  return (
    <>
      <h2>Ansættelsesforhold</h2>

      <Formik
        initialValues={{
          monthlyAmountAfterExpenses,
          employmentType,
          employmentYear,
          educationalLevel,
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          dispatch({
            type: 'set_employmentType',
            values: values.employmentType,
          });

          dispatch({
            type: 'set_employmentYear',
            values: values.employmentYear,
          });

          dispatch({
            type: 'set_educationalLevel',
            values: isEducation(values.employmentType)
              ? values.educationalLevel
              : undefined,
          });

          history.push('/kreditkort');
        }}
      >
        {(formikProps) => (
          <div className="mt-5">
            <div className="mt-5">
              <label className="label-default">Ansættelsesforhold</label>
              <select
                name="employmentType"
                value={formikProps.values.employmentType}
                onChange={formikProps.handleChange}
              >
                <option value="">Vælg ansættelsesforhold</option>
                {employmentTypeOptions.map((employmentType) => (
                  <option
                    key={employmentType.value}
                    value={employmentType.value}
                  >
                    {employmentType.label}
                  </option>
                ))}
              </select>

              {(formikProps.touched.employmentType ||
                formikProps.submitCount > 0) &&
                formikProps.errors.employmentType && (
                  <div className="invalid-feedback">
                    {formikProps.errors.employmentType}
                  </div>
                )}
            </div>

            {isJob(formikProps.values.employmentType) && (
              <div className="mt-5">
                <label className="label-default">
                  Hvornår blev du ansat i nuværende job
                </label>
                <YearSelect
                  dateOptions={DateOptions.PastDatesInclusive}
                  text="Vælg årstal"
                  value={formikProps.values.employmentYear?.getFullYear()}
                  onChange={(value) => {
                    const date: Date = value
                      ? new Date(Date.UTC(value, 0, 1))
                      : undefined;
                    formikProps.setFieldValue('employmentYear', date, true);
                  }}
                />

                {(formikProps.touched.employmentYear ||
                  formikProps.submitCount > 0) &&
                  formikProps.errors.employmentYear && (
                    <div className="invalid-feedback">
                      {formikProps.errors.employmentYear}
                    </div>
                  )}
              </div>
            )}

            <div className="container-btn">
              <BackButton />

              <button
                className="btn-next"
                type="submit"
                onClick={() => formikProps.submitForm()}
              >
                Næste
              </button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default MonthlyAmountAfterExpensesStep;
