/* tslint:disable */
/* eslint-disable */
/**
 * Motorloan API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Tracking,
    TrackingFromJSON,
    TrackingFromJSONTyped,
    TrackingToJSON,
} from './';

/**
 * 
 * @export
 * @interface Application
 */
export interface Application {
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    maritalStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    children?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    coApplicantInfoGovermentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    coApplicantInfoMobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    coApplicantInfoEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    coApplicantInfoEmploymentType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Application
     */
    coApplicantInfoEmploymentYear?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    accomodationType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Application
     */
    habitationYear?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    govermentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    citizenship?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    ancestralHomeland?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Application
     */
    inDenmarkSinceYear?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    residentPermit?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Application
     */
    residentPermitValidDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Application
     */
    memberOfAkasse?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    employmentType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Application
     */
    employmentYear?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    educationalLevel?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Application
     */
    dankort?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Application
     */
    debitCardsMasterCard?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Application
     */
    creditCardsMasterCard?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Application
     */
    creditCardsVisa?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Application
     */
    creditCardsDinersClub?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Application
     */
    creditCardsPetrol?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    childrenGroup1?: number;
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    childrenGroup2?: number;
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    childrenGroup3?: number;
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    childrenGroup4?: number;
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    shareOfExpenses?: number;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    applicationId: string;
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    paymentMethodId: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    mobile: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof Application
     */
    updated?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    resursApplicationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    resursRequest?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    resursResponse?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Application
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    trackingId?: string | null;
    /**
     * 
     * @type {Tracking}
     * @memberof Application
     */
    tracking?: Tracking;
}

export function ApplicationFromJSON(json: any): Application {
    return ApplicationFromJSONTyped(json, false);
}

export function ApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Application {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maritalStatus': !exists(json, 'maritalStatus') ? undefined : json['maritalStatus'],
        'children': !exists(json, 'children') ? undefined : json['children'],
        'coApplicantInfoGovermentId': !exists(json, 'coApplicantInfoGovermentId') ? undefined : json['coApplicantInfoGovermentId'],
        'coApplicantInfoMobile': !exists(json, 'coApplicantInfoMobile') ? undefined : json['coApplicantInfoMobile'],
        'coApplicantInfoEmail': !exists(json, 'coApplicantInfoEmail') ? undefined : json['coApplicantInfoEmail'],
        'coApplicantInfoEmploymentType': !exists(json, 'coApplicantInfoEmploymentType') ? undefined : json['coApplicantInfoEmploymentType'],
        'coApplicantInfoEmploymentYear': !exists(json, 'coApplicantInfoEmploymentYear') ? undefined : (json['coApplicantInfoEmploymentYear'] === null ? null : new Date(json['coApplicantInfoEmploymentYear'])),
        'accomodationType': !exists(json, 'accomodationType') ? undefined : json['accomodationType'],
        'habitationYear': !exists(json, 'habitationYear') ? undefined : (json['habitationYear'] === null ? null : new Date(json['habitationYear'])),
        'govermentId': !exists(json, 'govermentId') ? undefined : json['govermentId'],
        'citizenship': !exists(json, 'citizenship') ? undefined : json['citizenship'],
        'ancestralHomeland': !exists(json, 'ancestralHomeland') ? undefined : json['ancestralHomeland'],
        'inDenmarkSinceYear': !exists(json, 'inDenmarkSinceYear') ? undefined : (json['inDenmarkSinceYear'] === null ? null : new Date(json['inDenmarkSinceYear'])),
        'residentPermit': !exists(json, 'residentPermit') ? undefined : json['residentPermit'],
        'residentPermitValidDate': !exists(json, 'residentPermitValidDate') ? undefined : (json['residentPermitValidDate'] === null ? null : new Date(json['residentPermitValidDate'])),
        'memberOfAkasse': !exists(json, 'memberOfAkasse') ? undefined : json['memberOfAkasse'],
        'employmentType': !exists(json, 'employmentType') ? undefined : json['employmentType'],
        'employmentYear': !exists(json, 'employmentYear') ? undefined : (json['employmentYear'] === null ? null : new Date(json['employmentYear'])),
        'educationalLevel': !exists(json, 'educationalLevel') ? undefined : json['educationalLevel'],
        'dankort': !exists(json, 'dankort') ? undefined : json['dankort'],
        'debitCardsMasterCard': !exists(json, 'debitCardsMasterCard') ? undefined : json['debitCardsMasterCard'],
        'creditCardsMasterCard': !exists(json, 'creditCardsMasterCard') ? undefined : json['creditCardsMasterCard'],
        'creditCardsVisa': !exists(json, 'creditCardsVisa') ? undefined : json['creditCardsVisa'],
        'creditCardsDinersClub': !exists(json, 'creditCardsDinersClub') ? undefined : json['creditCardsDinersClub'],
        'creditCardsPetrol': !exists(json, 'creditCardsPetrol') ? undefined : json['creditCardsPetrol'],
        'childrenGroup1': !exists(json, 'childrenGroup1') ? undefined : json['childrenGroup1'],
        'childrenGroup2': !exists(json, 'childrenGroup2') ? undefined : json['childrenGroup2'],
        'childrenGroup3': !exists(json, 'childrenGroup3') ? undefined : json['childrenGroup3'],
        'childrenGroup4': !exists(json, 'childrenGroup4') ? undefined : json['childrenGroup4'],
        'shareOfExpenses': !exists(json, 'shareOfExpenses') ? undefined : json['shareOfExpenses'],
        'applicationId': json['applicationId'],
        'amount': json['amount'],
        'paymentMethodId': json['paymentMethodId'],
        'name': json['name'],
        'mobile': json['mobile'],
        'email': json['email'],
        'updated': !exists(json, 'updated') ? undefined : (json['updated'] === null ? null : new Date(json['updated'])),
        'resursApplicationId': !exists(json, 'resursApplicationId') ? undefined : json['resursApplicationId'],
        'resursRequest': !exists(json, 'resursRequest') ? undefined : json['resursRequest'],
        'resursResponse': !exists(json, 'resursResponse') ? undefined : json['resursResponse'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'trackingId': !exists(json, 'trackingId') ? undefined : json['trackingId'],
        'tracking': !exists(json, 'tracking') ? undefined : TrackingFromJSON(json['tracking']),
    };
}

export function ApplicationToJSON(value?: Application | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maritalStatus': value.maritalStatus,
        'children': value.children,
        'coApplicantInfoGovermentId': value.coApplicantInfoGovermentId,
        'coApplicantInfoMobile': value.coApplicantInfoMobile,
        'coApplicantInfoEmail': value.coApplicantInfoEmail,
        'coApplicantInfoEmploymentType': value.coApplicantInfoEmploymentType,
        'coApplicantInfoEmploymentYear': value.coApplicantInfoEmploymentYear === undefined ? undefined : (value.coApplicantInfoEmploymentYear === null ? null : value.coApplicantInfoEmploymentYear.toISOString()),
        'accomodationType': value.accomodationType,
        'habitationYear': value.habitationYear === undefined ? undefined : (value.habitationYear === null ? null : value.habitationYear.toISOString()),
        'govermentId': value.govermentId,
        'citizenship': value.citizenship,
        'ancestralHomeland': value.ancestralHomeland,
        'inDenmarkSinceYear': value.inDenmarkSinceYear === undefined ? undefined : (value.inDenmarkSinceYear === null ? null : value.inDenmarkSinceYear.toISOString()),
        'residentPermit': value.residentPermit,
        'residentPermitValidDate': value.residentPermitValidDate === undefined ? undefined : (value.residentPermitValidDate === null ? null : value.residentPermitValidDate.toISOString()),
        'memberOfAkasse': value.memberOfAkasse,
        'employmentType': value.employmentType,
        'employmentYear': value.employmentYear === undefined ? undefined : (value.employmentYear === null ? null : value.employmentYear.toISOString()),
        'educationalLevel': value.educationalLevel,
        'dankort': value.dankort,
        'debitCardsMasterCard': value.debitCardsMasterCard,
        'creditCardsMasterCard': value.creditCardsMasterCard,
        'creditCardsVisa': value.creditCardsVisa,
        'creditCardsDinersClub': value.creditCardsDinersClub,
        'creditCardsPetrol': value.creditCardsPetrol,
        'childrenGroup1': value.childrenGroup1,
        'childrenGroup2': value.childrenGroup2,
        'childrenGroup3': value.childrenGroup3,
        'childrenGroup4': value.childrenGroup4,
        'shareOfExpenses': value.shareOfExpenses,
        'applicationId': value.applicationId,
        'amount': value.amount,
        'paymentMethodId': value.paymentMethodId,
        'name': value.name,
        'mobile': value.mobile,
        'email': value.email,
        'updated': value.updated === undefined ? undefined : (value.updated === null ? null : value.updated.toISOString()),
        'resursApplicationId': value.resursApplicationId,
        'resursRequest': value.resursRequest,
        'resursResponse': value.resursResponse,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'trackingId': value.trackingId,
        'tracking': TrackingToJSON(value.tracking),
    };
}


