import React, { useState } from 'react';
import { Formik } from 'formik';
import { AdminApi } from '../../lib-api';
import { ApiConfiguration } from '../../api-configuration';
import { toast } from 'react-toastify';

const AdminLogin: React.FunctionComponent = () => {
  return (
    <>
      <p>
        <strong>Log ind</strong>
      </p>
      <Formik
        initialValues={{ username: '', password: '' }}
        onSubmit={async (values) => {
          const api = new AdminApi(ApiConfiguration);
          try {
            var response = await api.login({
              loginRequest: {
                username: values.username,
                password: values.password,
              },
            });

            if (response.apiKey) {
              // set session + redirect
              window.sessionStorage.setItem('apikey', response.apiKey);
              window.location.href = '/admin/search';
              return;
            }
          } catch (e) {
            toast.error('Forkert brugernavn eller adgangskode');
          }
        }}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <div className="mt-5">
              <div>
                <label className="label-default">Brugernavn</label>
                <input
                  type="text"
                  name="username"
                  placeholder="Indtast brugernavn"
                  defaultValue={formikProps.values.username}
                  disabled={formikProps.isSubmitting}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                />
              </div>

              <div className="mt-2">
                <label className="label-default">Adgangskode</label>
                <input
                  className="appearance-none "
                  type="password"
                  name="password"
                  placeholder="Indtast adgangskode"
                  autoComplete="off"
                  defaultValue={formikProps.values.password}
                  disabled={formikProps.isSubmitting}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                />
              </div>

              <div className="container-btn">
                <button className="btn btn-next" type="submit">
                  Log ind
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AdminLogin;
