import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import { subYears, addYears } from 'date-fns';
import { trackEvent } from '../util/gtm';
import YearSelect, { DateOptions } from './components/YearSelect';
import { dateFromCpr } from '../util/dates';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';
import { saveStateRemote } from './util';

const CitizenshipNonDanishStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();
  const { inDenmarkSinceYear, residentPermit, residentPermitValidDate } = state;
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const residentPermitOptions = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' },
    { value: 'G', label: 'G' },
    { value: 'H', label: 'H' },
    { value: 'J', label: 'J' },
    { value: 'K', label: 'K' },
    { value: 'L', label: 'L' },
    { value: 'P', label: 'P' },
    { value: 'R', label: 'R' },
    { value: 'Z', label: 'Z' },
  ];

  useEffect(() => {
    trackEvent('Flow', 'Load', 'CitizenshipNonDanishStep');
    dispatch({ type: 'set_step', step: Step.CitizenshipNonDanish });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => {
      await saveStateRemote(state);
    };
    saveState();
  }, []);

  const isResidentPermitTimeConstrained = (residentPermit: string) => {
    const notTimeConstrained = ['B', 'D', 'H', 'L'];
    return (
      residentPermit !== undefined &&
      residentPermit !== '' &&
      notTimeConstrained.includes(residentPermit) === false
    );
  };

  useEffect(() => {}, []);

  const schema = yup.object({
    inDenmarkSinceYear: yup
      .date()
      .min(subYears(today, 100), 'Vælge hvor længe har du boet i Danmark')
      .max(today, 'Vælge hvor længe har du boet i Danmark, tidligere end i dag')
      .test('cprBeforeOtherDates', '', function (inDenmarkSinceYear) {
        const birth = dateFromCpr(state.govermentId);

        birth.setMonth(0);
        birth.setDate(1);

        if (birth > inDenmarkSinceYear) {
          return this.createError({
            message:
              'Obs! Du har angivet et cpr-nummer, som ikke stemmer overens med hvor længe du har oplyst at du har boet i Danmark. Tjek at begge oplysninger er korrekte og gå til næste step.',
          });
        }

        return true;
      })
      .required('Vælge hvor længe har du boet i Danmark'),
    residentPermit: yup
      .string()
      .required('Vælge hvilken type opholdstilladelse har du'),
    residentPermitValidDate: yup.date().when('residentPermit', {
      is: (value) => value && isResidentPermitTimeConstrained(value),
      then: yup
        .date()
        .min(
          today,
          'Vælge hvornår din opholdstilladelse udløber, senere end i dag'
        )
        .max(
          addYears(today, 100),
          'Vælge hvornår din opholdstilladelse udløber'
        )
        .required('Vælge hvornår din opholdstilladelse udløber'),
      otherwise: yup.date(),
    }),
  });

  return (
    <>
      <h2>Opholdstilladelse</h2>

      <Formik
        initialValues={{
          inDenmarkSinceYear: inDenmarkSinceYear,
          residentPermit: residentPermit,
          residentPermitValidDate: residentPermitValidDate,
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          dispatch({
            type: 'set_inDenmarkSinceYear',
            values: values.inDenmarkSinceYear,
          });

          dispatch({
            type: 'set_residentPermit',
            values: values.residentPermit,
          });

          dispatch({
            type: 'set_residentPermitValidDate',
            values: values.residentPermitValidDate,
          });

          history.push('/raadighedsbeloeb');
        }}
      >
        {(formikProps) => {
          return (
            <div className="mt-5">
              <div>
                <label className="label-default">
                  Hvor længe har du boet i Danmark?
                </label>
                <YearSelect
                  dateOptions={DateOptions.PastDatesInclusive}
                  text="Vælge hvor længe har du boet i Danmark"
                  value={formikProps.values.inDenmarkSinceYear?.getFullYear()}
                  onChange={(value) => {
                    const date: Date = value
                      ? new Date(Date.UTC(value, 0, 1))
                      : undefined;
                    formikProps.setFieldValue('inDenmarkSinceYear', date, true);
                  }}
                />

                {(formikProps.touched.inDenmarkSinceYear ||
                  formikProps.submitCount > 0) &&
                  formikProps.errors.inDenmarkSinceYear && (
                    <div className="invalid-feedback">
                      {formikProps.errors.inDenmarkSinceYear}
                    </div>
                  )}
              </div>

              <div className="mt-5">
                <label className="label-default">
                  Hvilken type opholdstilladelse har du?
                </label>
                <select
                  name="residentPermit"
                  value={formikProps.values.residentPermit}
                  onChange={formikProps.handleChange}
                >
                  <option value="">
                    Vælge hvilken type opholdstilladelse har du
                  </option>
                  {residentPermitOptions.map((residentPermit) => (
                    <option
                      key={residentPermit.value}
                      value={residentPermit.value}
                    >
                      {residentPermit.label}
                    </option>
                  ))}
                </select>

                {(formikProps.touched.residentPermit ||
                  formikProps.submitCount > 0) &&
                  formikProps.errors.residentPermit && (
                    <div className="invalid-feedback">
                      {formikProps.errors.residentPermit}
                    </div>
                  )}
              </div>

              {isResidentPermitTimeConstrained(
                formikProps.values.residentPermit
              ) && (
                <div className="mt-5">
                  <label className="label-default">
                    Hvornår udløber din opholdstilladelse?
                  </label>
                  <YearSelect
                    dateOptions={DateOptions.FutureDatesInclusive}
                    text="Vælge hvornår din opholdstilladelse udløber"
                    value={formikProps.values.residentPermitValidDate?.getFullYear()}
                    onChange={(value) => {
                      const date: Date = value
                        ? new Date(Date.UTC(value, 11, 31))
                        : undefined;
                      formikProps.setFieldValue(
                        'residentPermitValidDate',
                        date,
                        true
                      );
                    }}
                  />

                  {(formikProps.touched.residentPermitValidDate ||
                    formikProps.submitCount > 0) &&
                    formikProps.errors.residentPermitValidDate && (
                      <div className="invalid-feedback">
                        {formikProps.errors.residentPermitValidDate}
                      </div>
                    )}
                </div>
              )}

              <div className="container-btn">
                <BackButton />
                <button
                  className="btn-next"
                  type="submit"
                  onClick={() => formikProps.submitForm()}
                >
                  Næste
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default CitizenshipNonDanishStep;
