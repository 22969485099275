import { ApplicationApi, GetApplicationStatusResponse } from '../../lib-api';
import { useState, useEffect } from 'react';
import { ApiConfiguration } from '../../api-configuration';
import { LoadingState } from './loading';

export function useStatus(applicationId: string) {
  const [status, setStatus] = useState<GetApplicationStatusResponse>();
  const [state, setState] = useState<LoadingState>(LoadingState.None);

  const load = async () => {
    
    try {
      const api = new ApplicationApi(ApiConfiguration);
      setState(LoadingState.Loading);
      const resp = await api.getApplicationStatus(
        {
          getStatusRequest: {
            applicationId: applicationId,
            successUrl: '?success',
            failedUrl: '?failed'
          }
        }
      );

      setStatus(resp);
      setState(LoadingState.Success);
    } catch (e) {
      setState(LoadingState.Error);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return {
    status: status,
    statusState: state,
  };
}
