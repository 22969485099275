import React, { useContext, useEffect } from 'react';
import { ApplicationFormStateContext } from './state';
import { useHistory } from 'react-router-dom';
import YesNo from './components/YesNo';
import { trackEvent } from '../util/gtm';
import { BackButton } from './components/BackButton';
import { scrollToTopOfContent } from './features/scroll';
import { Step } from './models/Step';
import { saveStateRemote } from './util';

const HasCoApplicantStep: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(ApplicationFormStateContext);
  const history = useHistory();


  useEffect(() => {
    trackEvent("Flow", "Load", "HasCoApplicantStep");
    dispatch({ type: 'set_step', step: Step.HasCoApplicant });
    scrollToTopOfContent();

    /* store state */
    const saveState = async () => { await saveStateRemote(state); }
    saveState();
  }, []);
  
  const handleNo = () => {
    dispatch({ 
      type: 'set_coApplicant', 
      coApplicantInfoGovermentId: undefined, 
      coApplicantInfoMobile: undefined, 
      coApplicantInfoEmail: undefined, 
      coApplicantInfoEmploymentType: undefined, 
      coApplicantInfoEmploymentYear: undefined
    });
    history.push('/boligform');
  };

  return (
    <>
      <h2>Medansøger</h2>

      <h3>
        Vil du tilføje en medansøger?<br />
        OBS! Det anbefales at tilføje en medansøger, da det øger sandsynligheden for at få bevilliget en kredit, når I er flere om at låne penge.
      </h3>

      <div className="mt-5">
        <YesNo  
          onYes={() => history.push('/medansoeger')}
          onNo={handleNo}
          >
        </YesNo>
      </div>

      <div className="container-btn">
        <BackButton />
      </div>
    </>
  );
};

export default HasCoApplicantStep;
